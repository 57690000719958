import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import InfoMessageModal from '../../components/InfoMessageModal';
import { useLayoutEffect, useRef, useState } from 'react';
import api_timestamp from '../../services/api_timestamp';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.scss';
import jwt from 'jwt-decode';


const Login = () => {

  useLayoutEffect(() => { localStorage.clear() }, [])

  const navigate = useNavigate()

  const [showModalStatus, setShowModalStatus] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const DataSessionStorage = (data) => {
    localStorage.setItem('user_id', data.user_id)
    localStorage.setItem('user_username', data.user_username)
    localStorage.setItem('user_firstname', data.user_firstname)
    localStorage.setItem('user_lastname', data.user_lastname)
    localStorage.setItem('user_email', data.user_email)
    localStorage.setItem('user_permission', data.user_permission)
    localStorage.setItem('user_superuser', data.user_is_superuser)
  }

  const abortController = useRef(new AbortController());


  const SubmitForm = async (e) => {
    e.preventDefault();
    const user_data = { username, password };

    const fetchData = async () => {

      abortController.current.abort();
      abortController.current = new AbortController();
      const signal = abortController.current.signal

      try {
        const response = await api_timestamp.post('api/login/', user_data, { signal, timeout: 3000 });

        localStorage.setItem('token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
        const data = jwt(response.data.access);
        DataSessionStorage(data);
        setUsername('');
        setPassword('');
        navigate('/');

      } catch (error: any) {
        setModalTitle('Falha ao realizar login');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.response?.status === 401) {
          setModalMessage('Usuário ou senha incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error.status === 499) {
          console.log('Request canceled by user')
        } else {
          setModalTitle('Erro desconhecido');
          setModalMessage('Aconteceu um erro inesperado ao efetuar login. Tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        }
      }
    }

    fetchData();
    return () => abortController.current.abort();
  };


  return (
    <Container fluid className={styles.mainContainer}>
      <Row className={`justify-content-center align-items-center ${styles.rowContainer}`}>
        <Col sx='auto' >
          <h3 className={styles.title}>Timesheet Login</h3>
          <Form onSubmit={SubmitForm} className={styles.formContainer}>
            <Row className='d-flex justify-content-center'>
              <Col xs='auto' className={styles.columnElement}>
                <Form.Label className={styles.label}>Usuário</Form.Label>
                <Form.Control type="text" placeholder="Informe o usuário" name="username" required onChange={e => setUsername(e.target.value)} />
                <Form.Label className={styles.label}>Senha</Form.Label>
                <Form.Control type="password" placeholder="Informe a senha" name="password" required onChange={e => setPassword(e.target.value)} />
              </Col>
            </Row>
            <Row className={` ${styles.rowButton}`}>
              <Col>
                <Button type="submit" className={`btn btn-light ${styles.confirmButton}`}>Confirmar</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <InfoMessageModal showModal={showModalStatus} onClose={() => setShowModalStatus(false)} modalTitle={modalTitle} modalMessage={modalMessage} />

    </Container>
  )
}

export default Login;