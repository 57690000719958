import { createContext, useContext, useState, Dispatch, ReactNode, SetStateAction } from 'react';


interface IniciativeContextData {
  iniciative: string
  setIniciative: Dispatch<SetStateAction<string | ''>>;
}

const IniciativeContext = createContext<IniciativeContextData | any>('all');

interface IniciativeProviderProps {
  children: ReactNode;
}

export const IniciativeProvider: React.FC<IniciativeProviderProps> = ({ children }) => {
  const [iniciative, setIniciative] = useState('all');
  
  const value: IniciativeContextData = {
    iniciative,
    setIniciative,
  };

  return <IniciativeContext.Provider value={value}>{children}</IniciativeContext.Provider>;
};

export const useIniciativeContext = (): IniciativeContextData => {
  const contextInitiative = useContext(IniciativeContext);
  if (!contextInitiative) {
    throw new Error('Texto de erro padrão');
  }
  return contextInitiative;
};
