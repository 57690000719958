import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footerContainer}>
        <p>F9C Global Security - Copyright ©<span id="current_year"></span> - v1.0.0 </p>
    </footer>
  )
};

export default Footer;