import { useErrorRedirectContext } from '../../../../context/global/ErrorRedirectContext';
import InfoMessageModal from '../../../../components/InfoMessageModal';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import SelectLoading from '../../../../components/SelectLoading';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api_timestamp from '../../../../services/api_timestamp';
import FrameLoading from '../../../../components/FrameLoading';
import styles from './RacUpdateForm.module.scss';
import ISave from '../../../../interfaces/ISave';
import IRac from '../../../../interfaces/IRac';
import { useEffect, useState } from 'react';


const RacUpdateForm = () => {

  const params = useParams()
  const navigate = useNavigate();

  const [file, setFile] = useState<File | null>(null)
  const [user, setUser] = useState<IRac[] | any>([])
  const [manager, setManager] = useState('');
  const [date, setDate] = useState('')

  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const [showModalStatus, setShowModalStatus] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const { setErrorRedirectValue } = useErrorRedirectContext();


  useEffect(() => {

    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      try {
        const userResponse = await api_timestamp.get<{racs: IRac}>('api/v1/timesheet/user/', { signal });
        setUser(userResponse.data);
        
        const generalRecordsResponse = await api_timestamp.get<ISave>(`api/v1/rac/records/${params.id}/`, { signal });
        setManager(generalRecordsResponse.data.manager);
        setDate(generalRecordsResponse.data.date);
      } catch (error: any) {
        setIsLoading(false)
        setModalTitle('Falha na requisição');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request filters canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      }
    };

    fetchData();
    return () => abortController.abort();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);


  // Selecionar arquivo
  const selecionarArquivo = (evento: React.ChangeEvent<HTMLInputElement>) => {
    if (evento.target.files?.length) {
      setFile(evento.target.files[0])
    } else {
      setFile(null)
    }
  }


  // Submeter formulário
  const onSubmitForm = (evento: React.FormEvent<HTMLFormElement>) => {
    evento.preventDefault()
    setIsLoading(true)

    const formData = new FormData();
    formData.append('manager', manager)
    formData.append('date', date)

    if (file) {
      formData.append('doc', file)
    }

    api_timestamp.request({
      url: `api/v1/rac/records/${params.id}/`,
      method: 'patch',
      data: formData
    })
      .then(() => {
        setIsLoading(false)
        navigate('/rac')
      })
      .catch((error) =>{
        setIsLoading(false)
        setModalTitle('Falha na requisição');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      })
  }
  
  
  return (
    <Container>
      <h3 className={styles.title}>Atualizar RAC</h3>
      <Form className={styles.formContainer} onSubmit={onSubmitForm}>
        <Row className='d-flex justify-content-center'>
          <Col xs='auto' className={styles.colForm}>
            <Form.Label className={styles.labelForm} column>Gerente</Form.Label>
            {user?.length === 0 ? (
              <SelectLoading selectClass={'form-select'} />
            ) : (
            <Form.Select value={manager} required onChange={evento => setManager(evento.target.value)}>
              {user.results && user?.results.map(item =>
                <option key={item.id} value={item.id}>
                  {item.first_name} {item.last_name}
                </option>)}
            </Form.Select>
            )}
            <Form.Label className={styles.labelForm} column>Data</Form.Label>
            <div>
              <Form.Control type="date" max='9999-12-31' required value={date} onChange={evento => setDate(evento.target.value)} />
            </div>
            <Form.Label className={styles.labelForm} column>Arquivo</Form.Label>
            <div>
              <Form.Control type="file" onChange={selecionarArquivo} />
            </div>
          </Col>
        </Row>
        <Row className={`d-flex justify-content-center ${styles.rowButton}`}>
          <Col xs='auto' className={styles.colButton}>
            <Button type="submit" className={`btn btn-light ${styles.confirmButton}`}>Confirmar</Button>
            <Link className={`btn btn-danger ${styles.cancelButton}`} to={'/rac/consultrac'}>Cancelar</Link>
          </Col> 
        </Row>
      </Form>

    {isLoading ? <FrameLoading loadingTitle={'Carregando...'} /> : null}
    
    <InfoMessageModal showModal={showModalStatus} onClose={() => setShowModalStatus(false)} modalTitle={modalTitle} modalMessage={modalMessage} />

    </Container>
  )
}

export default RacUpdateForm;
