import { Spinner, Form } from 'react-bootstrap';
import styles from './SelectLoading.module.scss'


const SelectLoading = ({selectClass}) => {
  return (
    <div className={styles.container}>
      <span className={styles.icon}>
        <span> <Spinner className={styles.spinner} /></span>
      </span>
      <div>
        <Form.Select className={`${selectClass} ${styles.loadingSelect}`} disabled={true}>
          <option >Carregando...</option>
        </Form.Select>
      </div>
    </div>
  )
}

export default SelectLoading