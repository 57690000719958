import { createContext,useContext,useState,FC,ReactNode,Dispatch,SetStateAction,useEffect } from 'react';
import { useIniciativeContext } from '../../timesheet/TimestampFilter/InitiativeProvider';
import { useStartDateContext } from '../../timesheet/TimestampFilter/StartDateProvider';
import { useEmployeeContext } from '../../timesheet/TimestampFilter/EmployeeProvider';
import { useEndDateContext } from '../../timesheet/TimestampFilter/EndDateProvider';
import { useStatusContext } from '../../timesheet/TimestampFilter/StatusProvider';
import { useOpportunityContext } from '../../rac/filters/OpportunityProvider';
import { useConsultantContext } from '../../rac/filters/ConsultantProvider';
import { useClientContext } from '../../rac/filters/ClientProvider';
import { useLocation } from 'react-router-dom';


interface GlobalContextProps {
  prevPath: string;
  setPrevPath: Dispatch<SetStateAction<string | ''>>;
  filterUpdateTimestamp: number;
}

const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

const allowedRoutesTimesheet = [
  '/timestamp_list',
  '/timestamp_list/timestamp_update/:id',
  '/timestamp_list/timestamp_copy/:id',
  '/timestamp_create',
];


const allowedRoutesRac = [
  '/rac/saverac',
  '/rac/saverac/:id',
  '/rac/consultrac',
  '/rac/consultrac/:id',
];

const isPathAllowedTimesheet = (path: string) => {
  return allowedRoutesTimesheet.some((route) =>
    new RegExp(`^${route.replace(/:\w+/g, '\\w+').replace(/\//g, '\\/')}$`).test(path)
  );
};

const isPathAllowedRac = (path: string) => {
  return allowedRoutesRac.some((route) =>
    new RegExp(`^${route.replace(/:\w+/g, '\\w+').replace(/\//g, '\\/')}$`).test(path)
  );
};

export const GlobalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation();
  const [prevPath, setPrevPath] = useState('');
  const [filterUpdateTimestamp, setFilterUpdateTimestamp] = useState(Date.now());

  // Timesheet Context Values
  const { setStartDate } = useStartDateContext();
  const { setEndDate } = useEndDateContext();
  const { setStatus } = useStatusContext();
  const { setIniciative } = useIniciativeContext();
  const { setEmployee } = useEmployeeContext();

  // Rac Context Values
  const { setConsultantFilter} = useConsultantContext();
  const { setClientFilter } = useClientContext();
  const { setOpportunityFilter } = useOpportunityContext();

  const value: GlobalContextProps = {
    prevPath,
    setPrevPath,
    filterUpdateTimestamp,
  };

  useEffect(() => {
    setPrevPath(location.pathname);

    if (!isPathAllowedTimesheet(prevPath)) {
      setEmployee('all');
      setIniciative('all');
      setStartDate('');
      setStatus('all');
      setEndDate('');
    }

    if (!isPathAllowedRac(prevPath)) {
      setConsultantFilter('');
      setClientFilter('');
      setOpportunityFilter('');
    }

    setFilterUpdateTimestamp(Date.now());
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export const useGlobalContext = (): GlobalContextProps => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }
  return context;
};
