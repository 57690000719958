import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './Rac.module.scss';
import api_timestamp from '../../services/api_timestamp';
import InfoMessageModal from '../../components/InfoMessageModal';
import { useState } from 'react';


const Rac = () => {

  const [showModalStatus, setShowModalStatus] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');

  
  const downloadFile = () => {
    api_timestamp.get( `${process.env.REACT_APP_BACKEND_URL}api/v1/timesheet/download_rac/`,{
    responseType: 'blob',
    params:{
      param_document_rac_category: 'default_document',
      }})
      .then(response => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'document_rac.doc');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setModalTitle('A requisição falhou')
        setModalMessage('Não foi possível realizer o download do arquivo');
        setShowModalStatus(true);
      });
  }


  return (
    <Container className={`d-flex align-items-center justify-content-center ${styles.container}`}>
      <Row className={styles.rowContainer}>
        <Col lg={6} md={6} sm={6} xs={12} className={styles.colElement}>
          <Card className={styles.cardContainer}>
            <Card.Body className={styles.cardBodyContent}>
              <h5 className="card-title">Solicitação de número</h5>
              <p className="card-text">Solicite aqui o <code>Número</code> RAC.</p>
              <Link to='createrac' className={`btn btn-light ${styles.buttonLink}`}>Solicitar Número</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} md={6} sm={6} xs={12} className={styles.colElement}>
          <Card className={styles.cardContainer}>
            <Card.Body className={styles.cardBodyContent}>
              <h5 className="card-title">Salvar RAC</h5>
              <p className="card-text">Salve aqui seus <code>RAC</code> registrado.</p>
              <Link to='saverac' className={`btn btn-light ${styles.buttonLink}`}>Salvar</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} md={6} sm={6} xs={12} className={styles.colElement}>
          <Card className={styles.cardContainer}>
            <Card.Body className={styles.cardBodyContent}>
              <h5 className="card-title">Consultar RAC</h5>
              <p className="card-text">Consulte aqui o <code>RAC</code> registrados.</p>
              <Link to='consultrac' className={`btn btn-light ${styles.buttonLink}`}>Consultar</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} md={6} sm={6} xs={12} className={styles.colElement}>
          <Card className={styles.cardContainer}>
            <Card.Body className={styles.cardBodyContent}>
              <h5 className="card-title">Download do RAC</h5>
              <p className="card-text">Faça download do <code>Arquivo</code> RAC.</p>
              <button onClick={downloadFile} className={`btn btn-light ${styles.buttonLink}`}>Download</button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <InfoMessageModal showModal={showModalStatus} onClose={() => setShowModalStatus(false)} modalTitle={modalTitle} modalMessage={modalMessage} />

    </Container>
  )
}

export default Rac;
