import { createContext, useContext, useState, Dispatch, ReactNode, SetStateAction } from 'react';


interface EndDateContextData {
  endDate: string
  setEndDate: Dispatch<SetStateAction<string | ''>>;
}

const EndDateContext = createContext<EndDateContextData | any>('');

interface DateProviderProps {
  children: ReactNode;
}

export const EndDateProvider: React.FC<DateProviderProps> = ({ children }) => {
  const [endDate, setEndDate] = useState('');
  const value: EndDateContextData = {
    endDate,
    setEndDate,
  };

  return <EndDateContext.Provider value={value}>{children}</EndDateContext.Provider>;
};

export const useEndDateContext = (): EndDateContextData => {
  const contextEndDate = useContext(EndDateContext);
  if (!contextEndDate) {
    throw new Error('Texto de erro padrão');
  }
  return contextEndDate;
};
