import axios from "axios";

const api_timestamp = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

let isRefreshing = false;

function addToken(config: any) {
  if (window.location.pathname !== '/login') {
    const access_token = localStorage.getItem('token');
    const refresh_token = localStorage.getItem('refresh_token');

    if (access_token) {
      config.headers['Authorization'] = `Bearer ${access_token}`;
    }
    config.timeout = config.timeout || 20000;

    if (!access_token && !refresh_token) {
      api_timestamp.interceptors.request.eject(addTokenInterceptor);
    }
  }
  return config;
}

const addTokenInterceptor = axios.interceptors.request.use(addToken, (error: any) => {
  return Promise.reject(error);
});

api_timestamp.interceptors.request.use(addToken, (error: any) => {
  return Promise.reject(error);
});

function updateToken(refreshToken: any) {

  if (isRefreshing) {
    const unauthorizedResponse = {
      data: {'status': 401},
      statusText: '401 Unauthorized',
    };
    return Promise.reject(unauthorizedResponse);
  }

  isRefreshing = true;

  return api_timestamp.post('api/refresh/', { refresh: refreshToken })
    .then((response: any) => {
      const { access } = response.data;
      localStorage.setItem('token', access);
      api_timestamp.defaults.headers['Authorization'] = `Bearer ${access}`;
      isRefreshing = false;
      return Promise.resolve(access);
    }).catch((error: any) => {
      isRefreshing = false;
      return Promise.reject(error);
    });
}

api_timestamp.interceptors.response.use((response) => {
  return response;
}, (error) => {

  if (error.code === "ECONNABORTED") {
    const timeoutResponse = {
      status: 408,
      statusText: 'Request Timeout',
    };
    return Promise.reject(timeoutResponse);
  } else if (error.message === "canceled" || error.code === "ERR_CANCELED") {
    const canceledResponse = {
      status: 499,
      statusText: 'Request Canceled'
    };

    return Promise.reject(canceledResponse);
  }

  if (window.location.pathname !== '/login') {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = localStorage.getItem('refresh_token');
      
      return updateToken(refreshToken).then((access_token) => {
        api_timestamp.defaults.headers['Authorization'] = `Bearer ${access_token}`;
        originalRequest.headers['Authorization'] = `Bearer ${access_token}`;

        delete originalRequest._retry;

        if (access_token !== refreshToken) {
          return api_timestamp(originalRequest);
        } else {
          return Promise.reject(error);
        }
      }).catch((error) => {
        return Promise.reject(error);
      });
    }
  }

  return Promise.reject(error);
});

export default api_timestamp;
