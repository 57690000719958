import { useErrorRedirectContext } from '../../../context/global/ErrorRedirectContext';
import ManagerInputValues from '../../../components/ManagerInputValues';
import portuguese_br from "react-date-object/locales/gregorian_pt_br";
import IDisplacementType from '../../../interfaces/IDisplacementType';
import InfoMessageModal from '../../../components/InfoMessageModal';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import IDisplacement from '../../../interfaces/IDisplacement';
import SelectLoading from '../../../components/SelectLoading';
import FrameLoading from '../../../components/FrameLoading';
import api_timestamp from '../../../services/api_timestamp';
import React, { useEffect, useRef, useState } from 'react';
import IInitiative from '../../../interfaces/IInitiative';
import IActivity from '../../../interfaces/IActivity';
import ILocation from '../../../interfaces/ILocation';
import { Link, useNavigate } from 'react-router-dom';
import ISponsor from '../../../interfaces/ISponsor';
import styles from './TimestampCreate.module.scss';
import ICreate from '../../../interfaces/ICreate';
import IClient from '../../../interfaces/IClient';
import DatePicker from 'react-multi-date-picker';
import IRac from '../../../interfaces/IRac';


const TimestampCreate = () => {

  // Utilizado para definir o tipo de cliente que será filtrado
  const client_valid = true

  // Necessário para realizar a navegação dinâmica entre rotas
  const navigate = useNavigate();

  // Necessário para manipular o valor de nome em um element que já possui value id
  const myRef = useRef<any>();

  // Criado para receber o json api backend
  const [contextData, setContextData] = useState<ICreate | any>([]);

  // Recebem os valores individuais dos vetores obtidos do backend api
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [displacements, setDisplacements] = useState<IDisplacement[]>([]);
  const [displacementTypes, setDisplacementTypes] = useState<IDisplacementType[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);
  const [initiatives, setInitiatives] = useState<IInitiative[]>([]);
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [sponsors, setSponsors] = useState<ISponsor[]>([]);
  const [rac, setRac] = useState<IRac[]>([])

  // Valores com vetor
  const [locationValue, setLocationValue] = useState<any>();
  const [displacementValue, setDisplacementValue] = useState<any>();
  const [displacementTypeValue, setdisplacementTypeValue] = useState<any>();
  const [clientValue, setClientValue] = useState<any>();
  const [initiativeValue, setInitiativeValue] = useState<any>();
  const [activityValue, setActivityValue] = useState<any>();
  const [sponsorValue, setSponsorValue] = useState<any>();
  const [racValue, setRacValue] = useState('');
  const [managerAdjustment, setManagerAdjustment] = useState('00:00');

  // Valores sem vetor
  const [descriptionActivity, setDescriptionActivity] = useState('');
  const [dateRegister, setDateRegister] = useState<Date | any>(new Date().toJSON());
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [rat, setRat] = useState('');
  const [ticket, setTicket] = useState('');
  const [negativeHours, setNegativeHours] = useState(false);
  const loadingTitle = 'Carregando...'

  // Serve para controlar as exibições
  const [element, setElement] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [descriptionValid, setDescriptionValid] = useState<boolean>(false);
  const startTimeInputRef = useRef<HTMLInputElement>(null);
  const endTimeInputRef = useRef<HTMLInputElement>(null);

  const [InitTimeValidetedError, setInitTimeValidetedError] = useState('')
  const [endTimeValidetedError, setEndTimeValidetedError] = useState('')
  const [startTimeGreaterThanEndTime, setStartTimeGreaterThanEndTime] = useState('')
  const [descriptionValidatedError, setDescriptionValidetedError] = useState('')
  const [sponsorValidatedError, setSponsorValidetedError] = useState('')

  const [user, setUser] = useState<IRac | any>([])
  const [userValue, setUserValue] = useState<any>();
  const selectClass = 'form-select-sm'

  const [showModalStatus, setShowModalStatus] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const { setErrorRedirectValue } = useErrorRedirectContext();


  async function fetchDataAndSetValues(locationId, abortController) {
    const signal = abortController.signal;
    setIsLoading(true)

    // Limpa os vetores para que inicie o efeito de loading
    setDisplacements([]);
    setDisplacementTypes([]);
    // setInitiatives([]);

    // Limpa os valores anteriores
    setDisplacementValue(null);
    setdisplacementTypeValue(null);
    // setInitiativeValue(null);

    try {
      const responseDisplacement = await api_timestamp.get('api/v1/timesheet/displacement/', {
        params: {
          location: locationId,
        },
        signal,
      });

      // Armazenando a nova lista de elementos no estado de displacement e adiciona o valor inicial da lista no setDisplacementValue
      setDisplacements(responseDisplacement.data);
      setDisplacementValue(responseDisplacement.data[0].id);

      const responseDisplacementType = await api_timestamp.get('api/v1/timesheet/displacement_type/', {
        params: {
          displacement: responseDisplacement.data[0].id,
        },
        signal
      });

      setDisplacementTypes(responseDisplacementType.data);
      setdisplacementTypeValue(responseDisplacementType.data[0].id);
      
    } catch (error: any) {
      setIsLoading(false);
      setModalTitle('Falha na requisição');

      if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
        setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
        setErrorRedirectValue(false);
        setShowModalStatus(true);
      } else if (error?.status === 499) {
        console.log('Request canceled by user');
      } else if (error?.response?.status === 404 || error?.response?.status === 400) {
        setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
        setShowModalStatus(true);
      } else if (error?.data?.status === 401) {
        setModalTitle('Sessão expirada');
        setModalMessage('Conecte-se novamente.');
        setShowModalStatus(true);
        setErrorRedirectValue(true);
      } else {
        setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
        setErrorRedirectValue(false);
        setShowModalStatus(true);
      }
    } finally {
      setIsLoading(false)
    }

    return () => abortController.abort();

  };


  const InitiativeRequest = async (abortController) => {
    const signal = abortController.signal;

    try {
      const responseInitiative = await api_timestamp.get('api/v1/timesheet/initiative/', {
        params: {
          client: contextData[0].const_client_rest,
          valid: client_valid
        },
        signal
      });
      setInitiatives(responseInitiative.data);
      setInitiativeValue(parseInt(contextData[0].const_client_initiative_rest));
      setActivityValue(contextData[0].const_activity_rest);
  
      // Atribui os valores do contexto
      setClientValue(contextData[0].const_client_rest);
    } catch (error: any) {
      setIsLoading(false);
      setModalTitle('Falha na requisição');

      if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
        setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
        setErrorRedirectValue(false);
        setShowModalStatus(true);
      } else if (error?.status === 499) {
        console.log('Request canceled by user');
      } else if (error?.response?.status === 404 || error?.response?.status === 400) {
        setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
        setShowModalStatus(true);
      } else if (error?.data?.status === 401) {
        setModalTitle('Sessão expirada');
        setModalMessage('Conecte-se novamente.');
        setShowModalStatus(true);
        setErrorRedirectValue(true);
      } else {
        setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
        setErrorRedirectValue(false);
        setShowModalStatus(true);
      }
    } finally {
      setIsLoading(false)
    }

    return () => abortController.abort();
  };


  // Habilita ou desabilita o input de startTime e endTime
  async function adjustInputRefState(startTimeInputRef, endTimeInputRef, isReadOnly) {
    startTimeInputRef.current.readOnly = isReadOnly;
    startTimeInputRef.current.disabled = isReadOnly;
    endTimeInputRef.current.readOnly = isReadOnly;
    endTimeInputRef.current.disabled = isReadOnly;
  }


  // Realiza a requisição inicial para o backend api 
  useEffect(() => {
    setIsLoading(true);

    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      try {
        const response_user = await api_timestamp.get('api/v1/timesheet/user/', { signal });
        setUser(response_user.data);

        const response_location = await api_timestamp.get('api/v1/timesheet/location/', { signal });
        setLocations(response_location.data);

        const response_client = await api_timestamp.get('api/v1/timesheet/client/', { signal });
        setClients(response_client.data)

        const response_context_view = await api_timestamp.get('api/v1/timesheet/context_view/', { signal });
        setContextData(response_context_view.data)

        const response_activity = await api_timestamp.get('api/v1/timesheet/activity/', { signal });
        setActivities(response_activity.data);
        setActivityValue(response_activity.data[0].id);

        const response_sponsor = await api_timestamp.get('api/v1/timesheet/sponsor/', { signal });
        setSponsors(response_sponsor.data);
        setSponsorValue(response_sponsor.data[0].id);

        const response_rac = await api_timestamp.get('api/v1/rac/records/', { signal });
        setRac(response_rac.data);

        setIsLoading(false);

      } catch (error: any) {
        setIsLoading(false);
        setModalTitle('Falha na requisição');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      }
    };
    fetchData();

    return () => {
      abortController.abort();
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleLocationChange = (event) => {
    // Valor inicial do evento
    const locationId = event.target.value;

    const abortController = new AbortController();

    // Adiciona o valor do evento no value de location
    setLocationValue(locationId);

    // Gatilho da função fetchDataAndSetValues
    fetchDataAndSetValues(locationId, abortController);

    // Descanso Remunerado: relativo ao id 5 ou Ponte Feriádo: relativo ao id 7
    if (locationId === '5' || locationId === '7') {
      // Ativa a animação de loading
      setElement(true);

      // Adiciona um valor inicial em starttime
      setStartTime(contextData[0].const_start_time_rest);
      setEndTime(contextData[0].const_end_time_rest);

      adjustInputRefState(startTimeInputRef, endTimeInputRef, true);

      InitiativeRequest(abortController);

      setDescriptionValid(false);
    } // Plantão: relativo ao id 6
    else if (locationId === '6') {
      setElement(true);

      adjustInputRefState(startTimeInputRef, endTimeInputRef, true);

      setStartTime(contextData[0].const_start_time_duty);
      setEndTime(contextData[0].const_end_time_duty);
      setClientValue(contextData[0].const_client_rest);

      InitiativeRequest(abortController);

      setDescriptionValid(false);
    } // Intervalo refeição: relativo ao id 8
    else if (locationId === '8') {
      setElement(true);
      setStartTime('');
      setEndTime('');

      adjustInputRefState(startTimeInputRef, endTimeInputRef, false);

      InitiativeRequest(abortController);

      setDescriptionValid(true);
      setDescriptionActivity('Intervalo Refeição');
    } // Em casa: relativo ao id 1, No Fabricande: relativo ao id 2, Site cliente: relativo ao id 3, Site F9C: relativo ao id 4
    else {
      adjustInputRefState(startTimeInputRef, endTimeInputRef, false);

      setElement(false);
      setDescriptionValid(false);
    }
  };


  const handleDisplacementChange = async (event) => {
    setdisplacementTypeValue(null);
    setDisplacementTypes([]);
    setDisplacementValue(event.target.value)

    try {
      const responseDisplacementType = await api_timestamp.get('api/v1/timesheet/displacement_type/', {
        params: {
          displacement: event.target.value,
        }
      });

      setDisplacementTypes(responseDisplacementType.data);
      setdisplacementTypeValue(responseDisplacementType.data[0].id);
    } catch (error: any) {
      setIsLoading(false)
      setModalTitle('Falha na requisição');

      if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
        setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
        setErrorRedirectValue(false);
        setShowModalStatus(true);
      } else if (error?.status === 499) {
        console.log('Request filters canceled by user');
      } else if (error?.response?.status === 404 || error?.response?.status === 400) {
        setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
        setShowModalStatus(true);
      } else if (error?.data?.status === 401) {
        setModalTitle('Sessão expirada');
        setModalMessage('Conecte-se novamente.');
        setShowModalStatus(true);
        setErrorRedirectValue(true);
      } else {
        setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
        setErrorRedirectValue(false);
        setShowModalStatus(true);
      }
    };
  };


  const handleDisplacementTypeChange = async (event) => {
    setdisplacementTypeValue(event.target.value);
  };


  const handleClientChange = async (event) => {
    setClientValue(event.target.value);
    setInitiatives([]);
    setInitiativeValue(null);

    try {
      const responseInitiave = await api_timestamp.get('api/v1/timesheet/initiative/', {
        params: {
          client: event.target.value,
          valid: client_valid,
        }
      });

      setInitiatives(responseInitiave.data);
      setInitiativeValue(responseInitiave.data[0].id);
    } catch (error: any) {
      setIsLoading(false)
      setModalTitle('Falha na requisição');

      if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
        setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
        setErrorRedirectValue(false);
        setShowModalStatus(true);
      } else if (error?.status === 499) {
        console.log('Request filters canceled by user');
      } else if (error?.response?.status === 404 || error?.response?.status === 400) {
        setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
        setShowModalStatus(true);
      } else if (error?.data?.status === 401) {
        setModalTitle('Sessão expirada');
        setModalMessage('Conecte-se novamente.');
        setShowModalStatus(true);
        setErrorRedirectValue(true);
      } else {
        setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
        setErrorRedirectValue(false);
        setShowModalStatus(true);
      }
    };
  };


  const handleInitiativeChange = async (event) => {
    const initiativeIdValue = event.target.value;
    setInitiativeValue(initiativeIdValue);
  };


  const SubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await Promise.all(dateRegister.map(async (event) => {
        const formData = new FormData();
        const dateValue = event.format("YYYY-MM-DD", ["Date"]);

        const userValueToSend = userValue || '';

        formData.append("user", userValueToSend);
        formData.append("date", dateValue);
        formData.append("start_time", startTime);
        formData.append("end_time", endTime);
        formData.append("location", locationValue);
        formData.append("displacement", displacementValue);
        formData.append("displacement_type", displacementTypeValue);
        formData.append("client_name", clientValue);
        formData.append("client_initiative", initiativeValue);
        formData.append("activity", activityValue);
        formData.append("description", descriptionActivity);
        formData.append("sponsor", sponsorValue);

        if (user?.user_is_superuser) {
          formData.append("manager_adjustment", managerAdjustment);
          formData.append("negative_hours", String(negativeHours));
        }

        if (racValue !== '' && racValue !== null) {
          formData.append("rac", racValue);
        }
        if (rat !== '' && rat !== null) {
          formData.append("rat", rat);
        }
        if (ticket !== '' && ticket !== null) {
          formData.append("ticket", ticket);
        }

        await api_timestamp.request({
          url: 'api/v1/timesheet/timerecord/',
          method: 'POST',
          data: formData
        });
      }));
      setIsLoading(false);
      navigate('/timestamp_list');
    } catch (error: any) {
      setIsLoading(false);
      setModalTitle('Falha na requisição');

      if (error?.response?.data?.start_time_greater_than_end_time) {
        setStartTimeGreaterThanEndTime(error.response.data.start_time_greater_than_end_time)
        setModalMessage(error.response.data.start_time_greater_than_end_time)
        setShowModalStatus(true);
      } else if (error?.response?.data?.validate_time_init) {
        setInitTimeValidetedError(error.response.data.validate_time_init)
        setModalMessage(error.response.data.validate_time_init)
        setShowModalStatus(true);
      } else if (error?.response?.data?.validate_time_end) {
        setEndTimeValidetedError(error.response.data.validate_time_end)
        setModalMessage(error.response.data.validate_time_end)
        setShowModalStatus(true);
      } else if (error?.response?.data?.validate_date_same_period) {
        setModalMessage(error.response.data.validate_date_same_period)
        setShowModalStatus(true);
      } else if (error?.response?.data?.user?.validate_user) {
        setModalMessage(error.response.data.user.validate_user)
        setShowModalStatus(true);
      } else if (error?.response?.data?.location?.validate_location) {
        setModalMessage(error.response.data.location.validate_location)
        setShowModalStatus(true);
      } else if (error?.response?.data?.displacement?.validate_displacement) {
        setModalMessage(error.response.data.data.displacement.validate_displacement)
        setShowModalStatus(true);
      } else if (error?.response?.data?.displacement_type?.validate_displacement_type) {
        setModalMessage(error.response.data.displacement_type.validate_displacement_type)
        setShowModalStatus(true);
      } else if (error?.response?.data?.client_name?.validate_client_name) {
        setModalMessage(error.response.data.client_name.validate_client_name)
        setShowModalStatus(true);
      } else if (error?.response?.data?.activity?.validate_activity) {
        setModalMessage(error.response.data.activity.validate_activity)
        setShowModalStatus(true);
      } else if (error?.response?.data?.sponsor?.validate_sponsor) {
        setSponsorValidetedError(error.response.data.sponsor.validate_sponsor)
        setModalMessage(error.response.data.sponsor.validate_sponsor)
        setShowModalStatus(true);
      } else if (error?.response?.data?.description?.validate_description) {
        setDescriptionValidetedError(error.response.data.description.validate_description)
        setModalMessage(error.response.data.description.validate_description)
        setShowModalStatus(true);
      } else if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
        setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
        setErrorRedirectValue(false);
        setShowModalStatus(true);
      } else if (error?.status === 499) {
        console.log('Request canceled by user');
      } else if (error?.response?.status === 404 || error?.response?.status === 400) {
        setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
        setShowModalStatus(true);
      } else if (error?.data?.status === 401) {
        setModalTitle('Sessão expirada');
        setModalMessage('Conecte-se novamente.');
        setShowModalStatus(true);
        setErrorRedirectValue(true);
      } else {
        setModalTitle('Falha desconhecida');
        setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
        setErrorRedirectValue(false);
        setShowModalStatus(true);
      }
    }
  };


  const handleRatInputChange = (event) => {
    const inputValue = event.target.value;
    const numbersOnly = inputValue.replace(/\D/g, '');
    setRat(numbersOnly);
  };


  const handleTicketInputChange = (event) => {
    const inputValue = event.target.value;
    const numbersOnly = inputValue.replace(/\D/g, '');
    setTicket(numbersOnly);
  };


  return (
    <Container className={styles.container}>
      <h3 className={styles.title}>Registro Timesheet</h3>
      <Form className={styles.formContainer} onSubmit={SubmitForm}>
        {user?.user_is_superuser ? (
          <ManagerInputValues setManagerAdjustment={setManagerAdjustment} negativeHours={negativeHours} setNegativeHours={setNegativeHours} />
          ) : null}
        <Row>
          {user?.user_is_superuser ? (
            <Col xs='auto' className={styles.consultant}>
              <Form.Label column>Consultor:</Form.Label>
              {user.length === 0 ? (
                <SelectLoading selectClass={selectClass} />
              ) : (
                <Form.Select required className="form-select-sm" value={userValue} onChange={e => setUserValue(e.target.value)}>
                  <option value=''>Selecione o consultor</option>
                  {user?.results?.map((userItem: any) => (
                    <option key={userItem.id} value={userItem.id}>{userItem.first_name} {userItem.last_name}</option>
                  ))}
                </Form.Select>
              )}
            </Col>
          ) : null}
          <Col xs='auto' className={styles.colDate}>
            <Form.Label>Data:</Form.Label>
            <Col xs="auto">
              <DatePicker locale={portuguese_br} required id={styles.datePickerElement} multiple format='YYYY-MM-DD' placeholder='DD/MM/YY' onChange={(date) => setDateRegister(date)} plugins={[<DatePanel />]} />
            </Col>
          </Col>
          <Col xs='auto'>
            <Form.Label column>Hora início:</Form.Label>
            <Form.Control isInvalid={InitTimeValidetedError !== '' || endTimeValidetedError !== '' || startTimeGreaterThanEndTime !== ''} ref={startTimeInputRef} id="id_start_time" value={startTime} required type='time' className={`form-control-sm ${styles.inputTime}`} onChange={e => setStartTime(e.target.value)} />
          </Col>
          <Col xs='auto'>
            <Form.Label column>Hora fim:</Form.Label>
            <Form.Control isInvalid={InitTimeValidetedError !== '' || endTimeValidetedError !== '' || startTimeGreaterThanEndTime !== ''} ref={endTimeInputRef} id="id_end_time" value={endTime} required type='time' className={`form-control-sm ${styles.inputTime}`} onChange={e => setEndTime(e.target.value)} />
          </Col>
        </Row>
        <Row>
          <Col xs='auto' className={styles.halfComponent}>
            <Form.Label htmlFor="location-select" column>Local de atuação:</Form.Label>
            {locations?.length === 0 ? (
              <SelectLoading selectClass={selectClass} />
            ) : (
              <Form.Select ref={myRef} className="form-select-sm" id="location-select" onChange={handleLocationChange}>
                {!locationValue && (<option value="">Selecione o local</option>)}
                {locations?.map((location: any) => (
                  <option key={location.id} value={location.id} data-item={location.id}>{location.name}</option>
                ))}
              </Form.Select>
            )}
          </Col>
          <Col xs='auto' className={styles.halfComponent}>
            <Form.Label htmlFor="displacement-select" column>Deslocamento:</Form.Label>
            {!locationValue ? (
              <Form.Select className="form-select-sm"><option value="">Selecione o deslocamento</option></Form.Select>
            ) : (displacements.length === 0 ? (
              <SelectLoading selectClass={selectClass} />
            ) : (
              <Form.Select className="form-select-sm" id="displacement-select" onChange={handleDisplacementChange} value={displacementValue || ''}>
                {displacements.map((displacement_type: IDisplacement) => (
                  <option key={displacement_type.id} value={displacement_type.id}>{displacement_type.name}</option>
                ))}
              </Form.Select>
            ))}
          </Col>
        </Row>
        <Row>
          <Col xs='auto' className={styles.totalComponent}>
            <Form.Label htmlFor="displacementType-select" column>Tipo de Deslocamento:</Form.Label>
            {locationValue ? (
              (displacementTypes.length === 0 ? (
                <SelectLoading selectClass={selectClass} />
              ) : (
                <Form.Select required className="form-select-sm" id="displacementType-select" onChange={handleDisplacementTypeChange} value={displacementTypeValue || ''}>
                  {displacementTypes.map((displacement_type: any) => (
                    <option key={displacement_type.id} value={displacement_type.id}>{displacement_type.name}</option>
                  ))}
                </Form.Select>
              ))
            ) : (!displacementValue ? (
              <Form.Select required className="form-select-sm"><option value="">Selecione o tipo de deslocamento</option></Form.Select>
            ) : (displacementTypes.length === 0 ? (
              <SelectLoading selectClass={selectClass} />
            ) : (
              <Form.Select required className="form-select-sm" id="displacementType-select" onChange={handleDisplacementTypeChange} value={displacementTypeValue || ''}>
                {displacementTypes.map((displacement_type: any) => (
                  <option key={displacement_type.id} value={displacement_type.id}>{displacement_type.name}</option>
                ))}
              </Form.Select>
            )))}
          </Col>
        </Row>
        <Row>
          <Col xs='auto' className={styles.totalComponent}>
            <Form.Label htmlFor="client-select" column>Cliente:</Form.Label>
            {clients?.length === 0 ? (
              <SelectLoading selectClass={selectClass} />
            ) : (element === false ? (
              <Form.Select required className="form-select-sm" onChange={handleClientChange} value={clientValue || ''}>
                {!clientValue && (<option value="">Selecione o cliente</option>)}
                {clients?.map((client: any) => (
                  <option id="option_teste" key={client.id} value={client.id}>{client.name}</option>
                ))}
              </Form.Select>
            ) : (
              <Form.Select required className="form-select-sm" onChange={handleClientChange} value={clientValue || ''}>
                {clients.map((client: any) => (
                  <option id="option_teste" key={client.id} value={client.id} selected={client.id === !clientValue} disabled={client.id !== !clientValue}>{client.name}</option>
                ))}
              </Form.Select>
            ))}
          </Col>
        </Row>
        <Row>
          <Col xs='auto' className={styles.totalComponent}>
            <Form.Label htmlFor="initiative-select" column>Oportunidade:</Form.Label>
            {!clientValue ? (
              <Form.Select required className="form-select-sm">
                <option value="">Selecione a iniciativa</option>
              </Form.Select>
            ) : (initiatives.length === 0 ? (
              <SelectLoading selectClass={selectClass} />
            ) : (!element ? (
              <Form.Select required className="form-select-sm" id="initiative-select" onChange={handleInitiativeChange} value={initiativeValue || ''}>
                {!initiativeValue && (<option value="">Selecione a oportunidade</option>)}
                {initiatives.map((initiative) => (
                  <option key={initiative.id} value={initiative.id}>{initiative.opportunity_id} - {initiative.name}</option>
                ))}
              </Form.Select>
            ) : (
              <Form.Select required className="form-select-sm" id="initiative-select" onChange={handleInitiativeChange} value={initiativeValue || ''}>
                {initiatives.map((initiative) => (
                  <option key={initiative.id} value={initiative.id} disabled={initiative.id !== initiativeValue}>{initiative.opportunity_id} - {initiative.name}</option>
                ))}
              </Form.Select>
            )))}
          </Col>
        </Row>
        <Row>
          <Col xs='auto' className={styles.halfComponent}>
            <Form.Label column>Tipo de Atividade:</Form.Label>
            {activities.length === 0 ? (
              <SelectLoading selectClass={selectClass} />
            ) : (!element ? (
              <Form.Select required className="form-select-sm" value={activityValue || ''} onChange={event => { setActivityValue(event.target.value) }}>
                {activities.map((activ: any) => (
                  <option key={activ.id} value={activ.id}>{activ.name}</option>
                ))}
              </Form.Select>
            ) : (
              <Form.Select required className="form-select-sm" value={activityValue || ''} onChange={event => { setActivityValue(event.target.value) }}>
                {activities.map((activ: any) => (
                  <option key={activ.id} value={activ.id} disabled={activ.id !== activityValue}>{activ.name}</option>
                ))}
              </Form.Select>
            ))}
          </Col>
          <Col xs='auto' className={styles.halfComponent}>
            <Form.Label column>Sponsor:</Form.Label>
            <Form.Select isInvalid={sponsorValidatedError !== ''} required className='form-select-sm' value={sponsorValue || ''} onChange={event => setSponsorValue(event.target.value)}>
              <option value='' disabled hidden>Selecione uma opção</option>
              {sponsors.map((item: any) =>
                <option key={item.id} value={item.id}>{item.name}</option>)}
            </Form.Select>
          </Col>
          <Col xs='auto' className={styles.textComponent}>
            <Form.Label column>Rac:</Form.Label>
            {(!rac ? (
              <SelectLoading selectClass={selectClass} />
            ) : (
              <Form.Select className="form-select-sm" onChange={event => setRacValue(event.target.value)} value={racValue}>
                <option value=''>Selecione o RAC</option>
                {rac.map((rac_item: any) => (
                  <option key={rac_item.id} value={rac_item.id} >
                    {rac_item.id} - {rac_item.client_name}
                  </option>
                ))}
              </Form.Select>
            ))}
          </Col>
          <Col xs='auto' className={styles.textComponent}>
            <Form.Label column>Rat:</Form.Label>
            <Form.Control placeholder='Número rat' className='form-control form-control-sm' value={rat} onChange={handleRatInputChange} />
          </Col>
          <Col xs='auto' className={styles.textComponent}>
            <Form.Label column>Ticket:</Form.Label>
            <Form.Control placeholder='Número ticket' className='form-control form-control-sm' value={ticket} onChange={handleTicketInputChange} />
          </Col>
        </Row>
        <Row>
          <Col xs="auto" className={styles.totalComponent}></Col>
          <Form.Group>
            <Form.Label column>Descrição da atividade:</Form.Label>
            {descriptionValid ? (
              <Form.Control disabled={descriptionValid} value={descriptionActivity} as='textarea' placeholder='Informe a descrição da atividade' required onChange={event => setDescriptionActivity(event.target.value)} />
            ) : (
              <Form.Control isInvalid={descriptionValidatedError !== ''} value={descriptionActivity} as='textarea' placeholder='Informe a descrição da atividade' required onChange={event => setDescriptionActivity(event.target.value)} />
            )}
          </Form.Group>
        </Row>
        &nbsp;
        <Row className={`d-flex ${styles.rowButton}`}>
          <Col>
            <Button type='submit' className={`btn btn-light ${styles.confirmButton}`}>Confirmar</Button>
            <Link to='/timestamp_list' className={`btn btn-danger ${styles.cancelButton}`}>Cancelar</Link>
          </Col>
        </Row>

        {isLoading ? (
          <FrameLoading loadingTitle={loadingTitle} />
        ) : null}

        <InfoMessageModal showModal={showModalStatus} onClose={() => setShowModalStatus(false)} modalTitle={modalTitle} modalMessage={modalMessage} />

      </Form>
    </Container>
  )
};

export default TimestampCreate;
