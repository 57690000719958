import { useTimesheetContext } from '../../../context/timesheet/TimestampList/TimesheetListProvider';
import { useIniciativeContext } from '../../../context/timesheet/TimestampFilter/InitiativeProvider';
import { useStartDateContext } from '../../../context/timesheet/TimestampFilter/StartDateProvider';
import { useEmployeeContext } from '../../../context/timesheet/TimestampFilter/EmployeeProvider';
import { useEndDateContext } from '../../../context/timesheet/TimestampFilter/EndDateProvider';
import { useStatusContext } from '../../../context/timesheet/TimestampFilter/StatusProvider';
import { Container, Form, Col, Row, Button, Table, Pagination } from 'react-bootstrap';
import { useErrorRedirectContext } from '../../../context/global/ErrorRedirectContext';
import ModalDeleteTimestampList from '../../../components/ModalDeleteTimestampList';
import InfoOverlayTriggerComponent from '../../../components/InfoOverlayTriggerComponent';
import { PencilSquare, Trash3, Files } from 'react-bootstrap-icons';
import InfoMessageModal from '../../../components/InfoMessageModal';
import api_timestamp from '../../../services/api_timestamp';
import FrameLoading from '../../../components/FrameLoading';
import EmptyMessage from '../../../components/EmptyMessage';
import ListLoading from '../../../components/ListLoading';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import styles from './TimestampList.module.scss';
import React from 'react';
import IconOverlayTriggerComponent from '../../../components/IconOverlayTriggerComponent';


const TimestampList: React.FC = () => {

  const { timestampElements, setTimestampElements } = useTimesheetContext();
  const { startDate, setStartDate } = useStartDateContext();
  const { endDate, setEndDate } = useEndDateContext();
  const { status, setStatus } = useStatusContext();
  const { iniciative, setIniciative } = useIniciativeContext();
  const { employee, setEmployee } = useEmployeeContext();
  const { setErrorRedirectValue } = useErrorRedirectContext();

  const text_description = 'Nenhuma'
  const text_data = 'Hora'
  const [registed, setRegisted] = useState('de trabalho cadastrada ainda.');

  // Props do modal
  const [dateList, setDateList] = useState('')
  const [deleteListId, setDeleteListId] = useState('')
  const [startTimeList, setStartTimeList] = useState('')
  const [endTimeList, setEndTimeList] = useState('')

  const iduser = sessionStorage.getItem('user_id')
  const iduserlogged = iduser?.toString()
  const [statusAll, setStatusAll] = useState('')
  const [show, setShow] = useState(false);
  const itemName = 'o Registro'

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const [showModalStatus, setShowModalStatus] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const loadingTitle = 'Carregando...'

  const abortController = useRef(new AbortController());


  // Realiza a requisição inicial de dados da lista e de usuários
  useEffect(() => {
    setTimestampElements([]);

    setStartDate(startDate)
    setEndDate(endDate)
    setStatus(status)

    const fetchData = async () => {
      try {
        abortController.current = new AbortController();

        const response = await api_timestamp.get('api/v1/timesheet/list/', {
          params: {
            filter_employee: employee,
            filter_start_date: startDate.toString(),
            filter_end_date: endDate,
            filter_client_initiative: iniciative,
            filter_status: status,
            filter_user: iduserlogged,
          },
          signal: abortController.current.signal,
          timeout: 360000
        });
        setTimestampElements(response.data);
        setIniciative(iniciative)
        setEmployee(employee)
      } catch (error: any) {
        setModalTitle('Falha na requisição');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          if (error?.response?.data?.error){
            setModalMessage(error?.response?.data?.error);
            const timestamp_data_none = { ...timestampElements, timestamp_data: [], normal_hours: '00', aditional_hours: '00', displacement: '00', adjustment: '00', total_hours: '00'};
            setTimestampElements(timestamp_data_none);
            setIniciative(iniciative);
            setEmployee(employee);
            setRegisted('encontrada');
          } else {
            setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          }
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      }
    }


    fetchData();
    return () => abortController.current.abort()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const indice_number = timestampElements?.timestamp_data ?? [];
  const itemsToShow = indice_number.slice(startIndex, endIndex);


  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };


  // Realiza as requisições de busca
  const sendRequest = async (e) => {
    e.preventDefault();
    setTimestampElements([])

    if (!abortController.current.signal.aborted) {
      abortController.current.abort();
    }

    const fetchData = async () => {
      try {
        abortController.current.abort();
        abortController.current = new AbortController();

        const response = await api_timestamp.get('api/v1/timesheet/list/', {
          params: {
            filter_employee: employee,
            filter_start_date: startDate.toString(),
            filter_end_date: endDate,
            filter_client_initiative: iniciative,
            filter_status: status,
            filter_user: iduserlogged,
          },
          signal: abortController.current.signal,
          timeout: 360000
        });
        setTimestampElements(response.data);
      } catch (error: any) {
        setModalTitle('Falha na requisição');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          if (error?.response?.data?.error){
            setModalMessage(error?.response?.data?.error);
            const timestamp_data_none = { ...timestampElements, timestamp_data: [], normal_hours: '00', aditional_hours: '00', displacement: '00', adjustment: '00', total_hours: '00'};
            setTimestampElements(timestamp_data_none);
            setIniciative(iniciative);
            setEmployee(employee);
            setRegisted('encontrada');
          } else {
            setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          }
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      }
    };

    fetchData();
    return () => abortController.current.abort()
  };


  // Acionada para alterar o valor de deleteListId e exibir o modal de exclusão
  const handleClickDelete = (item) => {
    setDeleteListId(item.pk)
    setDateList(item.fields.date)
    setStartTimeList(item.fields.start_time)
    setEndTimeList(item.fields.end_time)
    setShow(true)
  }


  // Acionada pelo confirmar do modal para excluir o item da lista
  const removeListItem = (e) => {
    api_timestamp.delete(`api/v1/timesheet/timerecord/${deleteListId}/`)
      .then(() => {
        if (timestampElements?.timestamp_data) {
          const updatedTimestampData = timestampElements?.timestamp_data.filter(item => item.pk !== deleteListId);
          const updatedRegister = { ...timestampElements, timestamp_data: updatedTimestampData };
          setTimestampElements(updatedRegister);
          sendRequest(e);
        }
      })
      .catch((error) => {
        setModalTitle('Falha na requisição');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      });

    setShow(false);
  }


  // Atualiza individualmente o status da lista
  const handleStatusUpdate = (e, register_pk) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('status', e.target.value)

    api_timestamp.request({
      url: `api/v1/timesheet/timerecord/${register_pk}/`,
      method: 'patch',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: formData
    })
      .then(() => {
        setModalTitle('Status da atualização')
        setModalMessage(`Status atualizado com sucesso!`);
        setShowModalStatus(true);
        sendRequest(e);
      }).catch((error) => {
        setModalTitle('Falha na requisição');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      })
  };


  // Atualiza todos os status da lista
  const updateAllStatus = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const updatePromises = itemsToShow.map(async (registerItem) => {
      const formData = new FormData();
      formData.append('status', statusAll);

      try {
        await api_timestamp.request({
          url: `api/v1/timesheet/timerecord/${registerItem.pk}/`,
          method: 'patch',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          data: formData,
        });
        setIsLoading(false);
        sendRequest(e);
      } catch (error: any) {
        setModalTitle('Falha na requisição');
        setIsLoading(false);
        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      }
    });

    await Promise.all(updatePromises);

    setStatusAll('');
    setIsLoading(false);
    await sendRequest(e);
  };


  const handleClose = () => setShow(false);


  const renderWithBreakLines = (str_value: string) => {
    const stringValueWithBrTags = str_value.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}<br />
      </React.Fragment>
    ));

    return stringValueWithBrTags;
  };
  

  return (
    <Container className={styles.container}>
      <h3 className={styles.title}>Lista da Horas Trabalhadas</h3>
      <Form>
        <Row>
          <Col className={styles.colSelectSearch}>
            {timestampElements?.hasAdminPermission && (
              <>
                <Form.Label className={styles.labelForm}>Funcionário:</Form.Label>
                <Form.Select className={styles.selectForm} value={employee} onChange={(e) => setEmployee(e.target.value)}>
                  <option key="all" value="all">Todos</option>
                  {timestampElements.user_list.map(item =>
                    <option key={item.user_id} value={item.user_id}>
                      {item.first_name} {item.last_name}
                    </option>
                  )}
                </Form.Select>
              </>
            )}
            <Form.Label className={styles.labelForm}>Oportunidade:</Form.Label>
            <Form.Select className={styles.selectForm} value={iniciative} onChange={(e) => setIniciative(e.target.value)}>
              <option key="all" value="all">Todos</option>
              {timestampElements?.opportunity_list?.opportunity?.map((item: any) =>
                <option key={item.client_initiative__opportunity_id} value={item.client_initiative__opportunity_id}>
                  {item.client_initiative__opportunity_id} - {item.client_initiative__name}
                </option>
              )}
            </Form.Select>
            <Form.Label className={styles.labelForm}>Data início:</Form.Label>
            <Form.Control className={styles.selectForm} type="date" required value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            <Form.Label className={styles.labelForm}>Data fim:</Form.Label>
            <Form.Control className={styles.selectForm} type="date" required value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            <Form.Label className={styles.labelForm}>Status:</Form.Label>
            <Form.Select className={styles.selectForm} value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="all">Todos</option>
              <option value="Approved">Aprovado</option>
              <option value="Blocked">Bloqueado</option>
              <option value="Pending">Pendente</option>
              <option value="Rejected">Reprovado</option>
            </Form.Select>
            <Button className={`btn btn-light ${styles.searchButton}`} onClick={sendRequest}>Buscar</Button>
          </Col>
        </Row>
      </Form>
      <Table striped bordered hover className={styles.tableContainer}>
        <thead className={styles.tableHeadContainer}>
          <tr>
            {timestampElements?.hasAdminPermission && (
              <th className={styles.tableHeadUpdate}>Consultor
                <InfoOverlayTriggerComponent descriptionElement={'Nome do consultor responsável por este registro'} />
              </th>
            )}
            <th className={styles.tableHeadUpdate}>Data
              <InfoOverlayTriggerComponent descriptionElement={'Data, hora de chegada e hora de saída'} />
            </th>
            <th className={styles.tableHeadUpdate}>Desloc
              <InfoOverlayTriggerComponent descriptionElement={'Deslocamento ida e volta'} />
            </th>
            <th className={styles.tableHeadUpdate}>Normal
              <InfoOverlayTriggerComponent descriptionElement={'Horas efetivamente trabalhadas'} />
            </th>
            <th className={styles.tableHeadUpdate}>Adic
              <InfoOverlayTriggerComponent descriptionElement={'Hora adicional em caso de horas extras trabalhadas'} />
            </th>
            <th className={styles.tableHeadUpdate}>Ajuste
              <InfoOverlayTriggerComponent descriptionElement={'Ajuste do gestor'} />
            </th>
            <th className={styles.tableHeadUpdate}>Sponsor
              <InfoOverlayTriggerComponent descriptionElement={'Empresa responsável pelo projeto'} />
            </th>
            <th className={styles.tableHeadUpdate}>Cliente
              <InfoOverlayTriggerComponent descriptionElement={'Número da oportunidade e nome do cliente'} />
            </th>
            <th className={styles.tableHeadUpdate}>Atividade
              <InfoOverlayTriggerComponent descriptionElement={'Local e tipo da atividade realizada'} />
            </th>
            <th className={styles.tableHeadUpdate}>Descrição
              <InfoOverlayTriggerComponent descriptionElement={'Descrição da atividade realizada e quando houver, RAC, RAT ou TICKET relacionada a atividade'} />
            </th>
            <th className={styles.tableHeadUpdate}>Status
              <InfoOverlayTriggerComponent descriptionElement={'Status do lançamento. Valores possíveis são: Pendente, Aprovado, Reprovado e Bloqueado'} />
            </th>
            <th className={styles.tableHeadUpdate}>Ações
              <InfoOverlayTriggerComponent descriptionElement={'Ações de copiar, editar e deletar um lançamento'} />
            </th>
          </tr>
        </thead>
        <tbody className={styles.tableBodyContainer}>
          {itemsToShow && itemsToShow.length > 0 ? (
            itemsToShow.map((timestamps: any) => (
              <tr key={timestamps.pk} className={styles.trBody}>
                {timestampElements?.hasAdminPermission && (
                  <td className={styles.tdConsultant}>{timestamps.fields.consultor}</td>
                )}
                <td className={styles.tdData}>{timestamps.fields.date}<br></br>{timestamps.fields.start_time}<br></br>{timestamps.fields.end_time}</td>
                <td className={styles.tdGoEndReturn}>{timestamps.fields.displacement_type_value_go === '00:00' ? '0' : timestamps.fields.displacement_type_value_go} - {timestamps.fields.displacement_type_value_return === '00:00' ? '0' : timestamps.fields.displacement_type_value_return}</td>
                <td className={styles.tdNormalHours}>{timestamps.fields.time_diff}</td>
                <td className={styles.tdAditionalHours}>{timestamps.fields.aditional_hour === '00:00' ? '0' : timestamps.fields.aditional_hour}</td>
                <td className={styles.tdManager}>{timestamps.fields.manager_adjustment === '00:00' ? '0' : timestamps.fields.manager_adjustment}</td>
                <td className={styles.tdSponsor}>{timestamps.fields.sponsor}</td>
                <td className={styles.tdClient}><div className={styles.classe_teste}>{timestamps.fields.client_initiative_id} - {timestamps.fields.client_name}</div></td>
                <td className={styles.tdActivity}>{timestamps.fields.location} - {timestamps.fields.activity}</td>
                <td className={styles.tdDescription}><span className={styles.tdSpan}><span>{timestamps.fields.rac !== 'None' ? renderWithBreakLines('Rac: ' + timestamps.fields.rac) : ''}  {timestamps.fields.rat !== 'None' ? renderWithBreakLines(' Rat: ' + timestamps.fields.rat) : ''}{timestamps.fields.ticket !== 'None' ? renderWithBreakLines('Ticket: ' + timestamps.fields.ticket) : ''}
                  {renderWithBreakLines(timestamps.fields.description)}</span></span></td>
                {timestampElements?.hasAdminPermission ? (
                  <td className={styles.tdStatus}>
                    <Form.Select size="sm" value={timestamps.fields.status} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleStatusUpdate(e, timestamps.pk)} >
                      <option value="" key="select">Selecione</option>
                      <option value="Approved" key="approved">Aprovado</option>
                      <option value="Blocked" key="blocked">Bloqueado</option>
                      <option value="Pending" key="pending">Pendente</option>
                      <option value="Rejected" key="rejected">Reprovado</option>
                    </Form.Select>

                  </td>
                ) : (
                  timestamps.fields.status === "Approved" ? (
                    <td className={styles.tdIconColors}>
                      <IconOverlayTriggerComponent descriptionElement={'Aprovado'} status={'Approved'} />
                    </td>
                  ) : timestamps.fields.status === "Blocked" ? (
                    <td className={styles.tdIconColors}>
                      <IconOverlayTriggerComponent descriptionElement={'Bloqueado'} status={'Blocked'} />
                    </td>
                  ) : timestamps.fields.status === "Rejected" ? (
                    <td className={styles.tdIconColors}>
                      <IconOverlayTriggerComponent descriptionElement={'Rejeitado'} status={'Rejected'} />
                    </td>
                  ) : (
                    <td className={styles.tdIconColors}>
                      <IconOverlayTriggerComponent descriptionElement={'Pendente'} status={'Pending'} />
                    </td>
                  )
                )}
                <td className={styles.tdIcons}>
                  {<RouterLink to={`timestamp_copy/${timestamps.pk}`}>
                    <Files className={styles.iconFiles} aria-label='Botão para copiar um elemento da lista' title='Copiar' />
                  </RouterLink>
                  }
                  {timestamps.fields.status === 'Pending' || timestampElements?.hasAdminPermission === true ? (
                    <>
                      <RouterLink to={`timestamp_update/${timestamps.pk}`}><PencilSquare className={styles.iconPencil} aria-label='Botão para atualizar um elemento da lista' title='Atualizar' /></RouterLink>
                      <Trash3 className={styles.iconTrash} onClick={() => handleClickDelete(timestamps)} aria-label="Botão para excluir um elemento da lista" title='Excluir' />
                    </>
                  ) : null}
                </td>
              </tr>
            ))) : timestampElements?.timestamp_data ? (

              <EmptyMessage text_description={text_description} text_data={text_data} registed={registed} />

            ) : (
            <ListLoading />
          )}
        </tbody>
      </Table>

      <Pagination className='d-flex justify-content-center align-items-center' size="sm" >
        <Pagination.First onClick={() => handlePageChange(1)} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
        {Array.from({ length: Math.ceil(indice_number.length / itemsPerPage) }, (_, index) => (
          <Pagination.Item key={index} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)} linkStyle={index + 1 === currentPage ? { color: '#FFF', backgroundColor: '#00244D', borderColor: '#042d5c' } : { color: '#042d5c' }} >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === Math.ceil(indice_number.length / itemsPerPage)} />
        <Pagination.Last onClick={() => handlePageChange(Math.ceil(indice_number.length / itemsPerPage))} />
      </Pagination>

      {timestampElements?.hasAdminPermission ? (
        <Form onSubmit={updateAllStatus} className={`d-flex justify-content-center align-items-center ${styles.formStatus}`}>
          <Form.Label className={styles.labelForm}>Aplicar status:</Form.Label>
          <Form.Select size="sm" required onChange={e => setStatusAll(e.target.value)} className={styles.formStatusSelect}>
            <option value="">Selecione</option>
            <option value="Approved">Aprovado</option>
            <option value="Blocked">Bloqueado</option>
            <option value="Pending">Pendente</option>
            <option value="Rejected">Reprovado</option>
          </Form.Select>
          <Button type='submit' className={`btn btn-light ${styles.formStatusButton}`}>Enviar</Button>
        </Form>
      ) : null}
      <br />

      <ModalDeleteTimestampList show={show} handleClose={handleClose} remove={removeListItem} itemName={itemName} dateList={dateList} startTimeList={startTimeList} endTimeList={endTimeList} />

      <InfoMessageModal showModal={showModalStatus} onClose={() => setShowModalStatus(false)} modalTitle={modalTitle} modalMessage={modalMessage} />

      {isLoading ? (<FrameLoading loadingTitle={loadingTitle} />) : null}

    </Container>
  )
}

export default TimestampList;
