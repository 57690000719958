import { useErrorRedirectContext } from '../../context/global/ErrorRedirectContext';
import styles from './InfoMessageModal.module.scss';
import { Modal, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';


const InfoMessageModal = ({ showModal, onClose, modalTitle, modalMessage }) => {
  const { errorRedirectValue, setErrorRedirectValue } = useErrorRedirectContext();
  const navigate = useNavigate()
  const location = useLocation()
  const {pathname} = location

  const handleModalCloseAndRedirect = () => {
    
    if (errorRedirectValue === true){
      navigate('/login')
    }

    if (pathname === '/rac/createrac'){
      navigate('/rac');
    }

    setErrorRedirectValue(false)
    return onClose();
  };

  
  return (
    <Modal show={showModal} onHide={handleModalCloseAndRedirect}>
      <Modal.Header closeButton id={styles.modalHeader}>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalMessage}
      </Modal.Body>
      <Modal.Footer>
        <Button className={`btn btn-light ${styles.modalConfirmButton}`} onClick={handleModalCloseAndRedirect}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoMessageModal;
