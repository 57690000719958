import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './Home.module.scss';


const TimestampInitialPage = () => {

  return (    
    <Container className={`d-flex align-items-center justify-content-center ${styles.containerElement}`}>
      <Row className={styles.rowElement}>
        <Col lg={6} md={6} sm={6} xs={12} className={styles.col}>
          <Card className={styles.cardContainer}>
            <Card.Body className={styles.cardBodyContent}>
              <h5 className="card-title">Lista de Horas Trabalhadas</h5>
              <p className="card-text">Veja aqui a lista de <code>Horas</code> trabalhadas.</p>
              <Link to="/timestamp_list" className={`btn btn-light ${styles.buttonLink}`}>Vá para Lista</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} md={6} sm={6} xs={12} className={styles.col}>
          <Card className={styles.cardContainer}>
            <Card.Body className={styles.cardBodyContent}>
              <h5 className="card-title">Registrar Horas Trabalhadas</h5>
              <p className="card-text">Registre aqui suas <code>Horas</code> trabalhadas.</p>
              <Link to="/timestamp_create" className={`btn btn-light ${styles.buttonLink}`}>Registrar Horas</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} md={6} sm={6} xs={12} className={styles.col}>
          <Card className={styles.cardContainer}>
            <Card.Body className={styles.cardBodyContent}>
              <h5 className="card-title">Acessar RAC</h5>
              <p className="card-text">Acesse aqui <code>Informações</code> sobre RAC.</p>
              <Link to="/rac" className={`btn btn-light ${styles.buttonLink}`}>Acessar Rac</Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

export default TimestampInitialPage;