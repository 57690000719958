import { createContext, useContext, useState, Dispatch, ReactNode, SetStateAction } from 'react';


interface StartDateContextData {
  startDate: string
  setStartDate: Dispatch<SetStateAction<string | ''>>;
}

const StartDateContext = createContext<StartDateContextData | any>('');

interface DateProviderProps {
  children: ReactNode;
}

export const StartDateProvider: React.FC<DateProviderProps> = ({ children }) => {
  const [startDate, setStartDate] = useState('');
  const value: StartDateContextData = {
    startDate,
    setStartDate,
  };

  return <StartDateContext.Provider value={value}>{children}</StartDateContext.Provider>;
};

export const useStartDateContext = (): StartDateContextData => {
  const contextStartDate = useContext(StartDateContext);
  if (!contextStartDate) {
    throw new Error('Texto de erro padrão');
  }
  return contextStartDate;
};
