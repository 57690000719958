import { useErrorRedirectContext } from '../../../context/global/ErrorRedirectContext';
import OverlayTriggerComponent from '../../../components/InfoOverlayTriggerComponent';
import { Col, Container, Pagination, Row, Table } from 'react-bootstrap';
import ModalGeneralDelete from '../../../components/ModalGeneralDelete';
import InfoMessageModal from '../../../components/InfoMessageModal';
import HolidaysResponse from '../../../interfaces/HolidaysResponse';
import { PencilSquare, Trash3 } from 'react-bootstrap-icons';
import api_timestamp from '../../../services/api_timestamp';
import EmptyMessage from '../../../components/EmptyMessage';
import ListLoading from '../../../components/ListLoading';
import { format, parseISO, addMinutes } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import styles from './HolidayList.module.scss';
import { Link } from 'react-router-dom';


const HolidayList = () => {

  const [holidays, setHolidays] = useState<HolidaysResponse>({ results: [], user_is_superuser: false });
  const [deleteElement, setDeleteElement] = useState('');
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [loading, setLoading] = useState(true);
  const [deleteId, setDeleteId] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const abortController = useRef(new AbortController());

  const [showModalStatus, setShowModalStatus] = useState(false);
  const { setErrorRedirectValue } = useErrorRedirectContext();
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');


  useEffect(() => {

    const fetchData = async () => {
      try {
        abortController.current = new AbortController();

        const response = await api_timestamp.get('api/v1/timesheet/holiday/', {
          signal: abortController.current.signal
        })
        setHolidays(response.data);
        setLoading(false);
      } catch (error: any) {
        setModalTitle('Falha na requisição');
        setLoading(false);

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      }
    }

    fetchData();
    return () => abortController.current.abort();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const indice_number = holidays.results ?? [];
  const itemsToShow = indice_number.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };


  const removeElementHoliday = () => {
    handleClose()
    
    api_timestamp
      .delete(`api/v1/timesheet/holiday/${deleteId}/`)
      .then(() => {
        const holidayList = holidays.results.filter((holiday) => holiday.id !== parseInt(deleteId));
        setHolidays({ ...holidays, results: holidayList });
      })
      .catch((error: any) => {
        setModalTitle('Falha na requisição');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      });
  };


  const holidayDelete = (item) => {
    setDeleteId(item.id);
    setDeleteElement(formatDateForInput(item.date))
    setShowModal(true);
  };


  function formatDateForInput(dataHoliday) {
    if (!dataHoliday) return '';
    const parsedDate = parseISO(dataHoliday);
    const adjustedDate = addMinutes(parsedDate, new Date().getTimezoneOffset());
    return format(adjustedDate, 'dd/MM/yyyy');
  }


  return (
    <Container className={styles.container}>
      <h3 className={styles.title}>Registros de Feriados</h3>
      <Row>
        <Col className="d-flex justify-content-center">
          <Table striped bordered hover className={`table-responsive ${styles.tableContainer}`}>
            <thead className={styles.tHead}>
              <tr>
                <th className={styles.thList}>Nome
                  <OverlayTriggerComponent descriptionElement={'Nome do feriado'} />
                </th>
                <th className={styles.thList}>Data
                  <OverlayTriggerComponent descriptionElement={'Data do feriado'} />
                </th>
                {holidays.user_is_superuser && (
                  <th className={styles.thList}>Ações
                    <OverlayTriggerComponent descriptionElement={'Ações de criação, atualização e deleção'} />
                  </th>
                )}
              </tr>
            </thead>
            <tbody className={styles.tBody}>
              {loading ? (
                <ListLoading />
              ) : itemsToShow.length > 0 ? (
                itemsToShow.map((item) => (
                  <tr key={item.id} className={styles.trBody}>
                    <td className={styles.tdList}>{item.name}</td>
                    <td className={styles.tdList}>{formatDateForInput(item.date)}</td>
                    {holidays.user_is_superuser && (
                      <td className={styles.tdList}>
                        <Link to={`holiday_update/${item.id}`}>
                          <PencilSquare className={styles.iconPencil} />
                        </Link>
                        <Trash3 className={styles.iconTrash} onClick={() => holidayDelete(item)} />
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <EmptyMessage text_description={'Nenhum'} text_data={'Feriado'} registed={'cadastrado ainda.'} />
              )}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Pagination className="d-flex justify-content-center align-items-center" size="sm">
        <Pagination.First onClick={() => handlePageChange(1)} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
        {Array.from({ length: Math.ceil(indice_number.length / itemsPerPage) }, (_, index) => (
          <Pagination.Item key={index} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)} linkStyle={index + 1 === currentPage ? { color: '#FFF', backgroundColor: '#00244D', borderColor: '#042d5c' } : { color: '#428dc7' }}> {index + 1} </Pagination.Item>))}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === Math.ceil(indice_number.length / itemsPerPage)} />
        <Pagination.Last onClick={() => handlePageChange(Math.ceil(indice_number.length / itemsPerPage))} />
      </Pagination>
      <br />

      <ModalGeneralDelete show={showModal} handleClose={handleClose} removeElement={removeElementHoliday} element_name={'o FERIADO'} deleteElement={deleteElement} describe_delete={'com data'} />

      <InfoMessageModal showModal={showModalStatus} onClose={() => setShowModalStatus(false)} modalTitle={modalTitle} modalMessage={modalMessage} />

    </Container>
  );
};

export default HolidayList;
