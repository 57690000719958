import styles from './ModalDeleteTimestampList.module.scss';
import { Modal, Button } from 'react-bootstrap';


const ModalDeleteTimestampList = ({ show, handleClose, remove, itemName, dateList, startTimeList, endTimeList}) => {
  return (
    <Modal show={show} onHide={handleClose} >
      <Modal.Header closeButton id={styles.header}>
        <Modal.Title>ATENÇÃO</Modal.Title>
      </Modal.Header>
      <Modal.Body>Você realmente deseja excluir {itemName} de data {dateList} entre {startTimeList} às {endTimeList} ?</Modal.Body>
      <Modal.Footer>
        <Button className={`btn btn-light ${styles.confirmButton}`} onClick={remove}>Confirmar</Button>
        <Button className={`btn btn-danger ${styles.cancelButton}`} onClick={handleClose}>Cancelar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeleteTimestampList;