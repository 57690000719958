import { useErrorRedirectContext } from '../../../context/global/ErrorRedirectContext';
import { useGlobalContext } from '../../../context/global/GlobalRouteContext';
import { Form, Col, Row, Button, Container } from 'react-bootstrap';
import ITimesheetRegister from '../../../interfaces/ITimesheetRegister';
import InfoMessageModal from '../../../components/InfoMessageModal';
import api_timestamp from '../../../services/api_timestamp';
import FrameLoading from '../../../components/FrameLoading';
import { useEffect, useRef, useState } from 'react';
import styles from './TimestampExport.module.scss';
import { saveAs } from 'file-saver';


const TimestampExport = () => {

  const [register, setRegister] = useState<ITimesheetRegister[] | any>([])
  const [employee, setEmployee] = useState('all')
  const [iniciative, setIniciative] = useState('all')
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const iduser = sessionStorage.getItem('user_id')
  const iduserlogged = iduser?.toString()
  const [status, setStatus] = useState('all')
  const [isLoading, setIsLoading] = useState<Boolean>(false)
  const [loadingTitle, setLoadingTitle] = useState('Carregando...')
  const { filterUpdateTimestamp } = useGlobalContext();

  const isMounted = useRef(false);
  const abortController = useRef(new AbortController());

  const [showModalStatus, setShowModalStatus] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const { setErrorRedirectValue } = useErrorRedirectContext();


  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        abortController.current.abort();
        abortController.current = new AbortController();

        const response = await api_timestamp.get<{ register: ITimesheetRegister }>('api/v1/timesheet/list/', {
          params: {
            filter_employee: employee,
            filter_start_date: startDate,
            filter_end_date: endDate,
            filter_client_initiative: iniciative,
            filter_status: status,
            filter_user: iduserlogged,
          },
          signal: abortController.current.signal,
          timeout: 360000,
        })
        setRegister(response.data);
        setIsLoading(false);

      } catch (error: any) {
        setIsLoading(false);
        setModalTitle('Falha na requisição');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      }
    };

    if (isMounted.current){
      fetchData();
    } else {
      isMounted.current = true
    }

    return () => abortController.current.abort();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterUpdateTimestamp]);


  const sendRequest = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    setLoadingTitle('Gerando arquivo xls')

    const fetchData = async () => {
      try {
        abortController.current.abort();

        abortController.current = new AbortController();
        const response = await api_timestamp.get('api/v1/timesheet/export/', {
          params: {
            filter_employee: employee,
            filter_start_date: startDate.toString(),
            filter_end_date: endDate,
            filter_client_initiative: iniciative,
            filter_status: status,
            filter_user: iduserlogged,
          },
          responseType: 'blob',
          signal: abortController.current.signal,
          timeout: 360000,
        });
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
          saveAs(blob, 'timestamps.xls');
          setIsLoading(false)
        } else {
          setModalTitle('Lista vazia');
          setModalMessage('O filtro selecionado não retornou registros')
          setShowModalStatus(true);
          setIsLoading(false)
        } 
      } catch (error: any) {
        setIsLoading(false)
        setModalTitle('Falha na requisição');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      }
    }

    fetchData();
    return () => abortController.current.abort();
  };


  return (
    <Container className={styles.container}>
      <h3 className={styles.title}>Exportar</h3>
      <Form>
        <Row sx={{ height: '40px' }}>
          <Col className="d-flex justify-content-center align-items-center">
            {register.hasAdminPermission && (
              <>
                <Form.Label className={styles.labelForm}>Funcionário:</Form.Label>
                <Form.Select className={styles.selectForm} value={employee} onChange={(e) => setEmployee(e.target.value)}>
                  <option value="all">Todos</option>
                  {register.user_list.map(item =>
                    <option key={item.id} value={item.user_id}>
                      {item.first_name} {item.last_name}
                    </option>
                  )}
                </Form.Select></>
            )}
            <Form.Label className={styles.labelForm}>Oportunidade:</Form.Label>
            <Form.Select className={`${styles.selectForm}`} value={iniciative} onChange={(e) => setIniciative(e.target.value)}>
              <option value="all">Todos</option>
              {register.opportunity_list?.opportunity?.map((item: any) =>
                <option key={item.client_initiative__opportunity_id} value={item.client_initiative__opportunity_id}>
                  {item.client_initiative__opportunity_id} - {item.client_initiative__name}
                </option>
              )}
            </Form.Select>
            <Form.Label className={styles.labelForm}>Data início:</Form.Label>
            <Form.Control className={styles.selectForm} type="date" required value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            <Form.Label className={styles.labelForm}>Data fim:</Form.Label>
            <Form.Control className={styles.selectForm} type="date" required value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            <Form.Label className={styles.labelForm}>Status:</Form.Label>
            <Form.Select className={styles.selectForm} value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="all">Todos</option>
              <option value="Approved">Aprovado</option>
              <option value="Blocked">Bloqueado</option>
              <option value="Pending">Pendente</option>
              <option value="Rejected">Reprovado</option>
            </Form.Select>
            <Button className={`btn btn-light ${styles.exportButton}`} onClick={sendRequest}>Exportar</Button>
            {isLoading ? (
              <FrameLoading loadingTitle={loadingTitle} />
            ) : ('')}
          </Col>
        </Row>
      </Form>

      <InfoMessageModal showModal={showModalStatus} onClose={() => setShowModalStatus(false)} modalTitle={modalTitle} modalMessage={modalMessage} />

    </Container>
  )
}

export default TimestampExport;
