import {createContext, useContext, useState} from 'react';


interface TimesheetContextData {
  timestampElements: any;
  setTimestampElements: React.Dispatch<React.SetStateAction<any>>;
}

const TimesheetContext = createContext<TimesheetContextData | undefined>(undefined);

export const TimesheetProvider = ({ children }) => {
  const [timestampElements, setTimestampElements] = useState<any>();
  const value: TimesheetContextData = {
    timestampElements,
    setTimestampElements,
  };

  return <TimesheetContext.Provider value={value}>{children}</TimesheetContext.Provider>;
};

export const useTimesheetContext = () => {
  const contextTimesheet = useContext(TimesheetContext);
  if (!contextTimesheet) {
    throw new Error('Texto de erro padrão');
  }
  return contextTimesheet;
};
