import { useErrorRedirectContext } from '../../../context/global/ErrorRedirectContext';
import InfoMessageModal from '../../../components/InfoMessageModal';
import { Col, Container, Form, Row, Button } from 'react-bootstrap';
import FrameLoading from '../../../components/FrameLoading';
import api_timestamp from '../../../services/api_timestamp';
import styles from './HolidayCreate.module.scss';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';


const HolidayCreate = () => {

  const navigate = useNavigate();

  const [nameHoliday, setNameHoliday] = useState('');
  const [dateHoliday, setDateHoliday] = useState('');

  const [showModalStatus, setShowModalStatus] = useState(false);
  const { setErrorRedirectValue } = useErrorRedirectContext();
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const [isLoading, setIsLoading] = useState<boolean>(false);


  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true)

    const formData = new FormData();
    formData.append('name', nameHoliday);
    formData.append('date', dateHoliday);

    api_timestamp.request({
      url: 'api/v1/timesheet/holiday/',
      method: 'post',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json', },
      data: formData,
    })
      .then(() => {
        setIsLoading(false)
        navigate('../holiday/holiday_list');
      })
      .catch((error: any) => {
        setModalTitle('Falha na requisição');
        setIsLoading(false)

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      });
  };


  return (
    <Container className={styles.container}>
      <h3 className={styles.title}>Cadastrar Feriado</h3>
      <Form onSubmit={onSubmitForm} className={styles.formContainer}>
        <Row className='d-flex justify-content-center'>
          <Col xs='auto' className={styles.colContainer}>
            <Form.Label className={styles.labelElement}>Nome do feriado</Form.Label>
            <Form.Control placeholder='Informe o feriado' required type='text' onChange={(event) => setNameHoliday(event.target.value)} />
            <Form.Label className={styles.labelElement}>Data</Form.Label>
            <Form.Control required type='date' value={dateHoliday} max='9999-12-31' onChange={(e) => setDateHoliday(e.target.value)} />
          </Col>
          <Col xs='auto' className={styles.rowButton}>
            <Button type='submit' className={`btn btn-light ${styles.confirmButton}`}>
              Confirmar
            </Button>
            <Button href='../../holiday/holiday_list' className={`btn btn-danger ${styles.cancelButton}`}>
              Cancelar
            </Button>
          </Col>
        </Row>
      </Form>

      {isLoading ? <FrameLoading loadingTitle={'Carregando...'} /> : null}

      <InfoMessageModal showModal={showModalStatus} onClose={() => setShowModalStatus(false)} modalTitle={modalTitle} modalMessage={modalMessage} />

    </Container>
  );
};

export default HolidayCreate;
