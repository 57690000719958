import { createContext, useContext, useState, Dispatch, ReactNode, SetStateAction } from 'react';


interface EmployeeContextData {
  employee: string
  setEmployee: Dispatch<SetStateAction<string | ''>>;
}

const EmployeeContext = createContext<EmployeeContextData | any>('all');

interface EmployeeProviderProps {
  children: ReactNode;
}

export const EmployeeProvider: React.FC<EmployeeProviderProps> = ({ children }) => {
  const [employee, setEmployee] = useState('all');
  const value: EmployeeContextData = {
    employee,
    setEmployee,
  };

  return <EmployeeContext.Provider value={value}>{children}</EmployeeContext.Provider>;
};

export const useEmployeeContext = (): EmployeeContextData => {
  const contextEmployee = useContext(EmployeeContext);
  if (!contextEmployee) {
    throw new Error('Texto de erro padrão');
  }
  return contextEmployee;
};
