import { useErrorRedirectContext } from '../../../context/global/ErrorRedirectContext';
import InfoMessageModal from '../../../components/InfoMessageModal';
import { Col, Container, Form, Row, Button } from 'react-bootstrap';
import SelectLoading from '../../../components/SelectLoading';
import api_timestamp from '../../../services/api_timestamp';
import { useNavigate, useParams } from 'react-router-dom';
import IHoliday from '../../../interfaces/IHoliday';
import React, { useEffect, useState } from 'react';
import styles from './HolidayUpdate.module.scss';


const HolidayUpdate = () => {

  const params = useParams();
  const navigate = useNavigate();

  const [register, setRegister] = useState<IHoliday>({ id: 0, name: '', date: '', });
  const [nameHoliday, setNameHoliday] = useState('');
  const [dataHoliday, setDataHoliday] = useState('');

  const [showModalStatus, setShowModalStatus] = useState(false);
  const { setErrorRedirectValue } = useErrorRedirectContext();
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');


  useEffect(() => {
    api_timestamp.get(`api/v1/timesheet/holiday/${params.id}/`)
      .then(resposta => {
        setRegister(resposta.data)
      }).catch((error: any) => {
        setModalTitle('Falha na requisição');
        
        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]) 


  useEffect(() => {
    setNameHoliday(register?.name || '')
    setDataHoliday(register?.date || '')
  }, [register])


  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault()
    const formData = new FormData();
    formData.append("name", nameHoliday)
    formData.append("date", dataHoliday)

    api_timestamp.request({
      url: `api/v1/timesheet/holiday/${params.id}/`,
      method: 'patch',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: formData
    })
      .then(() => {
        navigate('../holiday/holiday_list')
      })
      .catch((error) => {
        setModalTitle('Falha na requisição');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      })
  } 

  return (
    <Container className={styles.container}>
      <h3 className={styles.title}>Registro de Feriados</h3>
      <Form onSubmit={onSubmitForm} className={styles.formContainer}>
        <Row className='d-flex justify-content-center'>
          <Col xs='auto' className={styles.colContainer}>
            <Form.Label className={styles.labelElement}>Nome do feriado</Form.Label>
            {nameHoliday ? (
              <Form.Control type="text" value={nameHoliday} onChange={event => setNameHoliday(event.target.value)} />
            ) : (
              <SelectLoading selectClass={'form-select'} />
            )}
            <Form.Label className={styles.labelElement}>Data</Form.Label>
            <Form.Control type="date" value={dataHoliday} onChange={event => setDataHoliday(event.target.value)} />
          </Col>
          <Col xs='auto' className={styles.rowButton}>
            <Button type="submit" className={`btn btn-light ${styles.confirmButton}`}>Confirmar</Button>
            <Button href="../../../holiday/holiday_list" className={`btn btn-danger ${styles.cancelButton}`}>Cancelar </Button>
          </Col>
        </Row>
      </Form>

      <InfoMessageModal showModal={showModalStatus} onClose={() => setShowModalStatus(false)} modalTitle={modalTitle} modalMessage={modalMessage} />

    </Container >
  )
}

export default HolidayUpdate