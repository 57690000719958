import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";


interface OpportunityContextData {
  opportunityFilter: string;
  setOpportunityFilter: Dispatch<SetStateAction<string | ''>>;
}

const OpportunityContext = createContext<OpportunityContextData | any>('');

interface OpportunityContextProviderProps {
  children: ReactNode;
}

export const OpportunityProvider: React.FC<OpportunityContextProviderProps> = ({children}) => {
  const [opportunityFilter, setOpportunityFilter] = useState('')

  const value: OpportunityContextData = {
    opportunityFilter,
    setOpportunityFilter
  }

  return <OpportunityContext.Provider value={value}>{children}</OpportunityContext.Provider>
}

export const useOpportunityContext = () => {
  const ContextOpportunity = useContext(OpportunityContext);
  if (!ContextOpportunity){
    throw new Error('Texto padrão de erro')
  }
  return ContextOpportunity
}
