import { createContext, useContext, useState, Dispatch, ReactNode, SetStateAction } from 'react';


interface ConsultantContextData {
  consultantFilter: string
  setConsultantFilter: Dispatch<SetStateAction<string | ''>>;
}


const ConsultantContext = createContext<ConsultantContextData | any>('')

interface ConsultantProviderProps {
  children: ReactNode;
}

export const ConsultantProvider: React.FC<ConsultantProviderProps> = ({ children }) => {
  const [consultantFilter, setConsultantFilter] = useState('');
  
  const value: ConsultantContextData = {
    consultantFilter,
    setConsultantFilter,
  };

  return <ConsultantContext.Provider value={value}>{children}</ConsultantContext.Provider>;
};

export const useConsultantContext = (): ConsultantContextData => {
  const contextConsultant = useContext(ConsultantContext)
  if (!contextConsultant){
    throw new Error('Texto de erro padrão');
  }
  return contextConsultant;
}
