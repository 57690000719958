import 'bootstrap/dist/css/bootstrap.min.css';
import { TimesheetProvider } from './context/timesheet/TimestampList/TimesheetListProvider';
import { IniciativeProvider } from './context/timesheet/TimestampFilter/InitiativeProvider';
import { StartDateProvider } from './context/timesheet/TimestampFilter/StartDateProvider';
import { EmployeeProvider } from './context/timesheet/TimestampFilter/EmployeeProvider';
import { EndDateProvider } from './context/timesheet/TimestampFilter/EndDateProvider';
import { StatusProvider } from './context/timesheet/TimestampFilter/StatusProvider';
import { OpportunityProvider } from './context/rac/filters/OpportunityProvider';
import { ErrorRedirectProvider } from './context/global/ErrorRedirectContext';
import { ConsultantProvider } from './context/rac/filters/ConsultantProvider';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ClientProvider } from './context/rac/filters/ClientProvider';
import { GlobalProvider } from './context/global/GlobalRouteContext';
import RacPendingForm from './Pages/Rac/RacPending/RacPendingForm';
import TimestampCreate from './Pages/Timestamp/TimestampCreate';
import TimestampUpdate from './Pages/Timestamp/TimestampUpdate';
import TimestampConfig from './Pages/Timestamp/TimestampConfig';
import TimestampExport from './Pages/Timestamp/TimestampExport';
import RacUpdateForm from './Pages/Rac/RacUpdate/RacUpdateForm';
import TimestampList from './Pages/Timestamp/TimestampList';
import TimestampCopy from './Pages/Timestamp/TimestampCopy';
import HolidayCreate from './Pages/Holiday/HolidayCreate';
import HolidayUpdate from './Pages/Holiday/HolidayUpdate';
import HolidayList from './Pages/Holiday/HolidayList';
import { PrivateRoute } from './routes/PrivateRoute';
import RacPending from './Pages/Rac/RacPending';
import RacCreate from './Pages/Rac/RacCreate';
import RacUpdate from './Pages/Rac/RacUpdate';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './Pages/Login';
import Home from './Pages/Home';
import Rac from './Pages/Rac';


const App = () => {

  return (
    <Router>
      <ErrorRedirectProvider>
        <TimesheetProvider>
          <StartDateProvider>
            <EndDateProvider>
              <StatusProvider>
                <IniciativeProvider>
                  <EmployeeProvider>
                    <ConsultantProvider>
                      <ClientProvider>
                        <OpportunityProvider>
                          <GlobalProvider>

                            <Header />

                            <Routes>
                              {/* Route Holidays */}
                              <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
                              <Route path="/login" element={<Login />} />
                              <Route path="/timestamp_create" element={<PrivateRoute><TimestampCreate /></PrivateRoute>} />
                              <Route path="/timestamp_list" element={<PrivateRoute><TimestampList /></PrivateRoute>} />
                              <Route path="/timestamp_list/timestamp_update/:id" element={<PrivateRoute><TimestampUpdate /></PrivateRoute>} />
                              <Route path="/timestamp_list/timestamp_copy/:id" element={<PrivateRoute><TimestampCopy /></PrivateRoute>} />
                              <Route path="/timestamp/config" element={<PrivateRoute><TimestampConfig /></PrivateRoute>} />
                              <Route path="/timestamp/export" element={<PrivateRoute><TimestampExport /></PrivateRoute>} />

                              {/* Route Holidays */}
                              <Route path="/holiday/holiday_list" element={<PrivateRoute><HolidayList /></PrivateRoute>} />
                              <Route path="/holiday/holiday_list/holiday_create" element={<PrivateRoute><HolidayCreate /></PrivateRoute>} />
                              <Route path="/holiday/holiday_list/holiday_update/:id" element={<PrivateRoute><HolidayUpdate /></PrivateRoute>} />

                              {/* Route RAC */}
                              <Route path="rac/" element={<PrivateRoute><Rac /></PrivateRoute>} />
                              <Route path="rac/createrac" element={<PrivateRoute><RacCreate /></PrivateRoute>} />
                              <Route path="rac/saverac" element={<PrivateRoute><RacPending /></PrivateRoute>} />
                              <Route path="rac/saverac/:id" element={<PrivateRoute><RacPendingForm /></PrivateRoute>} />
                              <Route path="rac/consultrac" element={<PrivateRoute><RacUpdate /></PrivateRoute>} />
                              <Route path="rac/consultrac/:id" element={<PrivateRoute><RacUpdateForm /></PrivateRoute>} />

                              <Route path="*" element={<PrivateRoute><Home /></PrivateRoute>} />

                            </Routes>

                            <Footer />

                          </GlobalProvider>
                        </OpportunityProvider>
                      </ClientProvider>
                    </ConsultantProvider>
                  </EmployeeProvider>
                </IniciativeProvider>
              </StatusProvider>
            </EndDateProvider>
          </StartDateProvider>
        </TimesheetProvider>
      </ErrorRedirectProvider>
    </Router>
  )
};

export default App