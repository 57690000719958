import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { QuestionCircle } from 'react-bootstrap-icons'
import styles from './InfoOverlayTriggerComponent.module.scss'

const InfoOverlayTriggerComponent = ({ descriptionElement }) => {
  return (
    <OverlayTrigger placement="top" overlay={
      <Tooltip bsPrefix={styles.tooltipBox}>{descriptionElement}</Tooltip>}>
      <QuestionCircle className={styles.infoIcon} />
    </OverlayTrigger>
  )
}

export default InfoOverlayTriggerComponent