import { Container, Row, Col, Card } from 'react-bootstrap';
import styles from './TimestampConfig.module.scss';
import { Link } from 'react-router-dom';


const TimestampConfig = () => {
  return (
    <Container className={`d-flex align-items-center justify-content-center ${styles.containerElement}`}>
      <Row id={styles.rowElement}>
        <Col lg={6} md={6} sm={6} xs={12} className={styles.col}>
          <Card className={styles.card}>
            <Card.Body className={styles.divCard}>
              <h5 className="card-title">Visualizar e Cadastrar Feriados</h5>
              <p className="card-text">Visualize e cadastre aqui datas de <code>Feriados</code>.</p>
              <Link to='../holiday/holiday_list' className={`btn btn-light ${styles.link}`}>Acessar Feriados</Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default TimestampConfig;