import styles from './ModalGeneralDelete.module.scss';
import { Modal, Button } from 'react-bootstrap';


const ModalGeneralDelete = ({ show, handleClose, removeElement, element_name, deleteElement, describe_delete}) => {
  return (
    <Modal show={show} onHide={handleClose} >
      <Modal.Header closeButton id={styles.header}>
        <Modal.Title>ATENÇÃO</Modal.Title>
      </Modal.Header>
      <Modal.Body>Você realmente deseja excluir {element_name} {describe_delete} {deleteElement} ?</Modal.Body>
      <Modal.Footer>
        <Button className={`btn btn-light ${styles.confirmButton}`} onClick={removeElement}>Confirmar</Button>
        <Button className={`btn btn-danger ${styles.cancelButton}`} onClick={handleClose}>Cancelar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalGeneralDelete;