import styles from './ListLoading.module.scss';


const ListLoading = () => {
  return (
    <tr>
      <td colSpan={12} id={styles.tdLoading}>
        <div id={styles.divLoading} className="d-inline-flex align-items-center">
          <div role="status" id={styles.divLoadingSpinner} className='spinner-border text-dark me-2' />
          <h3 className="text-dark align-self-center" id={styles.loadingH3}>Carregando...</h3>
        </div>
      </td>
    </tr>
  )
}

export default ListLoading