import RacCreatedInfoMessageModal from '../../../components/RacCreatedInfoMessageModal';
import { useErrorRedirectContext } from '../../../context/global/ErrorRedirectContext';
import { Container, Form, Row, Col, Button, FormSelect } from 'react-bootstrap';
import InfoMessageModal from '../../../components/InfoMessageModal';
import SelectLoading from '../../../components/SelectLoading';
import api_timestamp from '../../../services/api_timestamp';
import FrameLoading from '../../../components/FrameLoading';
import styles from './CreateRac.module.scss';
import IRac from '../../../interfaces/IRac';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


const RacCreate = () => {

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [registerClients, setRegisterClients] = useState<IRac | any>([])
  const [client, setClient] = useState('')
  const [date, setDate] = useState('')

  const [showModalStatus, setShowModalStatus] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const { setErrorRedirectValue } = useErrorRedirectContext();

  const [racNumberMessageModal, setRacNumberMessageModal] = useState('');
  const [racClientMessageModal, setRacClientMessageModal] = useState('');
  const [racDateMessageModal, setRacDateMessageModal] = useState('');
  const [showRacModal, setShowRacModal] = useState(false);
  const [racTitleModal, setRacTitleModal] = useState('');


  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    api_timestamp.get<{ racs: IRac }>('api/v1/timesheet/client/', { signal, timeout: 15000 })
      .then(resposta => {
        setRegisterClients(resposta.data)
      }).catch((error) => {
        setModalTitle('Falha na requisição');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request filters canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      })

    return () => abortController.abort();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])


  const onSubmitForm = (evento: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true)

    evento.preventDefault()
    const formData = new FormData();
    formData.append('client', client)
    formData.append('date', date)

    api_timestamp.request({
      url: 'api/v1/rac/register/',
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        setClient('')
        setDate('')
        setIsLoading(false)
        setRacTitleModal('RAC criada com sucesso');
        setRacNumberMessageModal('Número rac:' + response.data.id)
        setRacClientMessageModal('Cliente:' + response.data.client)
        setRacDateMessageModal('Data de criação: ' + response.data.date)
        setShowRacModal(true);
      })
      .catch((error) => {
        setModalTitle('Falha na requisição');
        setIsLoading(false)

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 401 || error?.response?.status === 404) {
          setModalMessage('Não foi possível criar um novo registro RAC. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      })
  }

  return (
    <Container>
      <h3 className={styles.title}>Criar Rac</h3>
      <Form onSubmit={onSubmitForm} className={styles.formContainer}>
        <Row className='d-flex justify-content-center'>
          <Col xs='auto' className={styles.colForm}>
            <Form.Label column className={styles.labelForm}>Cliente:</Form.Label>
            {registerClients.length === 0 ? (
              <SelectLoading selectClass={'select-control'} />
            ) : (
              <FormSelect className="form-control" value={client} required onChange={evento => setClient(evento.target.value)}>
                <option value=''>Selecione</option>
                {registerClients.map(item =>
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>)}
              </FormSelect>
            )}
            <Form.Label column className={styles.labelForm}>Data:</Form.Label>
            <Form.Control type="date" max='9999-12-31' value={date} required onChange={evento => setDate(evento.target.value)} />
          </Col>
          <Col xs='auto' className={styles.colButton}>
            <Button type="submit" className={`btn btn-light ${styles.confirmButton}`}>Confirmar</Button>
            <Link className={`btn btn-danger ${styles.cancelButton}`} to='../rac'>Cancelar</Link>
          </Col>
        </Row>
      </Form>

      {isLoading ? <FrameLoading loadingTitle={'Carregando...'} /> : null}

      <InfoMessageModal
        modalTitle={modalTitle}
        modalMessage={modalMessage}
        showModal={showModalStatus}
        onClose={() => setShowModalStatus(false)}
      />

      <RacCreatedInfoMessageModal 
        showRacModal={showRacModal}
        racTitleModal={racTitleModal}
        onClose={() => setShowRacModal(false)}
        racNumberMessageModal={racNumberMessageModal}
        racClientMessageModal={racClientMessageModal}
        racDateMessageModal={racDateMessageModal}
      />

    </Container>
  )
}

export default RacCreate;
