import { createContext, useContext, useState, Dispatch, ReactNode, SetStateAction } from 'react';


interface StatusContextData {
  status: string;
  setStatus: Dispatch<SetStateAction<string>>;
}

const StatusContext = createContext<StatusContextData | undefined>(undefined);

interface StatusProviderProps {
  children: ReactNode;
}

export const StatusProvider: React.FC<StatusProviderProps> = ({ children }) => {
  const [status, setStatus] = useState('all');

  const value: StatusContextData = {
    status,
    setStatus,
  };

  return <StatusContext.Provider value={value}>{children}</StatusContext.Provider>;
};

export const useStatusContext = (): StatusContextData => {
  const context = useContext(StatusContext);
  if (!context) {
    throw new Error('Texto de erro padrão');
  }
  return context;
};
