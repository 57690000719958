import {createContext, useContext, useState} from 'react';


interface ErrorRedirectContextData {
  errorRedirectValue: boolean;
  setErrorRedirectValue: React.Dispatch<React.SetStateAction<any>>;
}

const ErrorRedirectContext = createContext<ErrorRedirectContextData | undefined>(undefined);

export const ErrorRedirectProvider = ({ children }) => {
  const [errorRedirectValue, setErrorRedirectValue] = useState<boolean>(false);
  const value: ErrorRedirectContextData = {
    errorRedirectValue,
    setErrorRedirectValue,
  };

  return <ErrorRedirectContext.Provider value={value}>{children}</ErrorRedirectContext.Provider>;
};

export const useErrorRedirectContext = () => {
  const contextErrorRedirect = useContext(ErrorRedirectContext);
  if (!contextErrorRedirect) {
    throw new Error('Texto de erro padrão');
  }
  return contextErrorRedirect;
};
