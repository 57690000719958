import styles from './RacCreatedInfoMessageModal.module.scss';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const RacCreatedInfoMessageModal = ({ showRacModal, onClose, racTitleModal, racNumberMessageModal, racClientMessageModal, racDateMessageModal }) => {
  const navigate = useNavigate()

  const handleModalCloseAndRedirect = () => {
    navigate('/rac');
    return onClose();
  };
  
  return (
    <Modal show={showRacModal} onHide={handleModalCloseAndRedirect}>
      <Modal.Header closeButton id={styles.modalHeader}>
        <Modal.Title>{racTitleModal}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {racNumberMessageModal}<br />
        {racClientMessageModal}<br />
        {racDateMessageModal}
      </Modal.Body>
      <Modal.Footer>
        <Button className={`btn btn-light ${styles.modalConfirmButton}`} onClick={handleModalCloseAndRedirect}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RacCreatedInfoMessageModal;
