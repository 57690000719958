import styles from './ManagerInputValues.module.scss';
import { Row, Col, Form } from 'react-bootstrap';


const ManagerInputValues = ({ managerAdjustment, setManagerAdjustment, negativeHours, setNegativeHours }: any) => {
  return (
    <Row xs='auto' className={`d-flex justify-content-end ${styles.horizontalAlign}`}>
        <Col xs='auto' style={{margin: '0px', padding: '0px'}}>
          <Form.Label column >Ajuste do gestor:</Form.Label>
        </Col>
        <Col xs='auto' className='d-flex align-items-center' style={{marginLeft: '-5px'}}>
          <Form.Control className="form-control-sm" id={styles.time} type='time' value={managerAdjustment} onChange={e => setManagerAdjustment(e.target.value)} />
        </Col>
        <Col xs='auto' className='d-flex align-items-center'>
          <Form.Label column style={{paddingRight: '5px'}}>Horas negativas:</Form.Label>
          <Form.Check id={styles.checkbox} checked={negativeHours} onChange={e => setNegativeHours(e.target.checked)} />
        </Col>
    </Row>
  )
}

export default ManagerInputValues
