import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";


interface ClientContextData {
  clientFilter: string;
  setClientFilter: Dispatch<SetStateAction<string | ''>>;
}

const ClientContext = createContext<ClientContextData | any>('');

interface ClientContextProviderProps {
  children: ReactNode
}

export const ClientProvider: React.FC<ClientContextProviderProps> = ({children}) => {
  const [clientFilter, setClientFilter] = useState('')

  const value: ClientContextData = {
    clientFilter,
    setClientFilter
  }

  return <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
}

export const useClientContext = (): ClientContextData => {
  const ContextClient = useContext(ClientContext)
  if (!ContextClient){
    throw new Error('Erro padrão')
  }
  return ContextClient
}
