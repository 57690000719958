import { useTimesheetContext } from '../../context/timesheet/TimestampList/TimesheetListProvider';
import logoOnspot from '../../assets/Onspot_logo.png';
import { Link, useLocation } from 'react-router-dom';
import logoF9C from '../../assets/f9c_new_logo_dark_blue.png';
import { useEffect, useState } from 'react';
import styles from './Header.module.scss';
import moment from 'moment';


export const Header = () => {

  const {timestampElements} = useTimesheetContext();

  const location = useLocation()
  const TimestampListPage = location.pathname === '/timestamp_list'
  const TimestampHolidayListPage = location.pathname === '/holiday/holiday_list'
  const RacListPending = location.pathname === '/rac/consultrac'
  const RacListSaved = location.pathname === '/rac/saverac'
  const user_first_name = localStorage.getItem('user_firstname')
  const user_lastname = localStorage.getItem('user_lastname')
  const id_user = localStorage.getItem('user_id')
  const user_superuser = localStorage.getItem('user_superuser')

  const GetCurrentDate = () => {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
      const interval = setInterval(() => {
        setDate(new Date());
      }, 1000);

      return () => clearInterval(interval);
    }, []);

    const dateStringWithTime = moment(date).format('DD/MM/YYYY - HH:mm:ss');
    return dateStringWithTime;
  };

  const logout = () => {
    localStorage.clear()
  }

  return (
    <div className={styles.divContent}>
      <div className={`navbar ${styles.header}`}>
        <img className={`my-2 ${styles.logoF9c}`} src={logoF9C} alt="Logo F9C" />
        <div className={styles.leftContent}>
          <p className={styles.item}>F9C Security LTDA | {GetCurrentDate()}</p>
          <span className={styles.spanUser}>{id_user ? 'Usuário: ' : null} <strong>{user_first_name ? user_first_name : null} {user_lastname ? user_lastname : ''}</strong></span>
          {TimestampListPage && (
            <span>
              {timestampElements && (
                <span>
                  <span className={styles.span}> - Horas Normais: <strong>{timestampElements?.normal_hours}</strong></span>
                  <span className={styles.span}> - Horas Adicionais: <strong>{timestampElements?.aditional_hours}</strong></span>
                  <span className={styles.span}> - Horas Deslocamentos: <strong>{timestampElements?.displacement}</strong></span>
                  <span className={styles.span}> - Horas Ajuste: <strong>{timestampElements?.adjustment}</strong></span>
                  <span className={styles.span}> - Total Geral: <strong>{timestampElements?.total_hours}</strong></span>
                </span>
              )}
            </span>
          )}
        </div>
        <img className={`my-2 ${styles.logoOnSpot}`} src={logoOnspot} alt="Logo Onspot" />
      </div>
      <div>
        {id_user && location.pathname !== '/login' ?
          <div className={`navbar navbar-expand-lg  ${styles.navButtons}`}>
            <div className={`${styles.buttonItem}`}>
              <Link to='/' className={`btn btn-light btn-sm ${styles.link}`}>Inicio</Link>
              <Link to='/timestamp_list' className={`btn btn-light btn-sm ${styles.link}`}>Registros</Link>
              <Link to='/timestamp/export' className={`btn btn-light btn-sm ${styles.link}`}>Exportar</Link>
              <Link to='/timestamp/config' className={`btn btn-light btn-sm ${styles.link}`}>Configuração</Link>
              <Link to='/login' onClick={logout} className={`btn btn-light btn-sm ${styles.logout}`}>Sair</Link>
            </div>
              {(TimestampListPage) && (<Link to='/timestamp_create' className={`btn btn-light btn-sm ${styles.buttonList}`}>Registrar horas</Link>)}
              {(RacListPending || RacListSaved) && (<Link to='../rac' className={`btn btn-light btn-sm ${styles.buttonList}`}>Voltar</Link>)}
              {(TimestampHolidayListPage  && user_superuser === 'true') && (<Link to='../holiday/holiday_list/holiday_create' className={`btn btn-light btn-sm ${styles.buttonList}`}>Registrar Feriado</Link>)}
          </div>
          : null}
      </div>
    </div>
  )

}
export default Header;