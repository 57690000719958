import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CircleFill } from 'react-bootstrap-icons'
import styles from './IconOverlayTriggerComponent.module.scss'

const IconOverlayTriggerComponent = ({ descriptionElement, status }) => {
  return (
    <OverlayTrigger placement="top" overlay={
      <Tooltip bsPrefix={status === 'Approved' ? styles.tooltipBoxApproved : (status === 'Blocked' ? styles.tooltipBoxBlocked : (status === 'Pending' ? styles.tooltipBoxPending : (status === 'Rejected' ? styles.tooltipBoxRejected : undefined)))}>
        {descriptionElement}
      </Tooltip>
      }>
      <div>
        {status === 'Approved' ? (
          <CircleFill className={styles.infoIconGreenApproved} />
        ) : status === 'Blocked' ? (
          <CircleFill className={styles.infoIconBlocked} />
        ) : status === 'Pending' ? (
          <CircleFill className={styles.infoIconOrangePending} />
        ) : (
          <CircleFill className={styles.infoIconBlueRejected} />
        )}
      </div>
    </OverlayTrigger>
  )
}

export default IconOverlayTriggerComponent