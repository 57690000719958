
const FrameLoading = ({loadingTitle}) => {
  return (
    <div className="d-flex align-items-center justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999 }}>
      <div className="spinner-border text-light me-3" role="status" style={{ width: '3rem', height: '3rem' }}>
      </div>
      <div className="d-flex align-items-center" style={{ animation: 'none' }}>
        <h3 className="text-light">{loadingTitle}</h3>
      </div>
    </div>
  )
}

export default FrameLoading