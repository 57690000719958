import { Button, Col, Container, Form, FormSelect, Pagination, Row, Table } from 'react-bootstrap';
import { useOpportunityContext } from '../../../context/rac/filters/OpportunityProvider';
import { useConsultantContext } from '../../../context/rac/filters/ConsultantProvider';
import { useErrorRedirectContext } from '../../../context/global/ErrorRedirectContext';
import OverlayTriggerComponent from '../../../components/InfoOverlayTriggerComponent';
import { useClientContext } from '../../../context/rac/filters/ClientProvider';
import { useGlobalContext } from '../../../context/global/GlobalRouteContext';
import ModalGeneralDelete from '../../../components/ModalGeneralDelete';
import InfoMessageModal from '../../../components/InfoMessageModal';
import { PencilSquare, Trash3 } from 'react-bootstrap-icons';
import EmptyMessage from '../../../components/EmptyMessage';
import api_timestamp from '../../../services/api_timestamp';
import ListLoading from '../../../components/ListLoading';
import IRacResponse from '../../../interfaces/IList';
import { useEffect, useRef, useState } from 'react';
import styles from './RacPending.module.scss';
import ISave from '../../../interfaces/ISave';
import IRac from '../../../interfaces/IRac';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';


const RacPending = () => {

  const { opportunityFilter, setOpportunityFilter } = useOpportunityContext();
  const { consultantFilter, setConsultantFilter } = useConsultantContext();
  const { clientFilter, setClientFilter } = useClientContext();
  const { filterUpdateTimestamp } = useGlobalContext();

  const [opportunityValue, setOpportunityValue] = useState<any>([])
  const [client, setClient] = useState<IRac | any>([])

  const [user, setUser] = useState<IRac | any>([])
  const [rac, setRac] = useState<ISave | any>([])
  const [deleteId, setDeleteId] = useState('')
  const [show, setShow] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const [showModalStatus, setShowModalStatus] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const { setErrorRedirectValue } = useErrorRedirectContext();

  const abortController = useRef(new AbortController());

  const isMountedFilter = useRef(false)
  const isMountedList = useRef(false)


  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      try {
        const userResponse = await api_timestamp.get<{ racs: IRac }>('api/v1/timesheet/user/', { signal });
        setUser(userResponse.data);

        const opportunityResponse = await api_timestamp.get<{ racs: IRac }>('api/v1/timesheet/initiative/rac_initiative_filter/', { signal });
        setOpportunityValue(opportunityResponse.data);

        const clientResponse = await api_timestamp.get<{ racs: IRac }>('api/v1/timesheet/client/', { signal });
        setClient(clientResponse.data);
      } catch (error: any) {
        setModalTitle('Falha na requisição');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request filters canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      }
    };

    if (isMountedFilter.current) {
      fetchData();
    } else {
      isMountedFilter.current = true
    }

    return () => abortController.abort();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterUpdateTimestamp]);


  useEffect(() => {
    setRac([]);

    const fetchData = async () => {
      try {
        abortController.current = new AbortController();

        const response = await api_timestamp.get<IRacResponse>('api/v1/rac/list/', {
          params: {
            rac_manager: consultantFilter,
            rac_clients: clientFilter,
            rac_opportunity: opportunityFilter,
            list_type: 'Pending',
          },
          signal: abortController.current.signal,
          timeout: 360000,
        })

        setRac(response.data);
      } catch (error: any) {
        setModalTitle('Falha na requisição');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      }
    };

    if (isMountedList.current) {
      fetchData();
    } else {
      isMountedList.current = true
    }

    return () => abortController.current.abort();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterUpdateTimestamp]);


  const handleSearchPendingRac = (e) => {
    e.preventDefault();
    setRac([])

    const fetchData = async () => {
      try {
        abortController.current.abort();
        abortController.current = new AbortController();

        const response = await api_timestamp.get<{ saves: ISave }>('api/v1/rac/list/', {
          params: {
            rac_manager: consultantFilter,
            rac_clients: clientFilter,
            rac_opportunity: opportunityFilter,
            list_type: 'Pending',
          },
          signal: abortController.current.signal,
          timeout: 360000,
        });
        setRac(response.data)
      } catch (error: any) {
        setModalTitle('Falha na requisição');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      }
    }

    fetchData();
    return () => abortController.current.abort();
  }


  const indice_number = rac.results ?? [];
  const itemsToShow = indice_number.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };


  const handleClickDelete = (item) => {
    setDeleteId(item.id)
    setShow(true)
  }


  const removeElementRac = () => {
    api_timestamp.delete(`api/v1/rac/records/${deleteId}/`)
      .then(() => {
        const racList = rac.results.filter(item => item.id !== deleteId)
        setRac({ ...rac, results: racList })
      }).catch((error: any) => {
        setModalTitle('Falha na requisição');

        if (error?.status === 408 || error?.code === 'ERR_NETWORK' || error?.response?.status === 500) {
          setModalMessage('A requisição falhou por não obter resposta do servidor. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        } else if (error?.status === 499) {
          console.log('Request canceled by user');
        } else if (error?.response?.status === 404 || error?.response?.status === 400) {
          setModalMessage('A página solicitada não foi encontrada ou a requisição enviada está incorreta. Por favor, tente novamente ou entre em contato com o suporte.');
          setShowModalStatus(true);
        } else if (error?.data?.status === 401) {
          setModalTitle('Sessão expirada');
          setModalMessage('Conecte-se novamente.');
          setShowModalStatus(true);
          setErrorRedirectValue(true);
        } else {
          setModalMessage('Ocorreu uma falha desconhecida. Por favor, tente novamente ou entre em contato com o suporte.');
          setErrorRedirectValue(false);
          setShowModalStatus(true);
        }
      })
    setShow(false)
  }


  function formatDateForInput(dataHoliday) {
    if (!dataHoliday) return '';
    return format(new Date(dataHoliday), 'dd/MM/yyyy');
  }


  const handleClose = () => setShow(false);


  return (
    <Container className={styles.mainContainer}>
      <h3 className={styles.title}>RAC's Pendentes</h3>
      <Form >
        <Row >
          <Col className="d-flex justify-content-left align-items-center">
            {user?.user_is_superuser ? (
              <>
                <Form.Label className={styles.labelForm}>Consultor :</Form.Label>
                <Form.Select className={`form-control ${styles.selectForm}`} value={consultantFilter} onChange={e => setConsultantFilter(e.target.value)}>
                  <option value=''>Todos</option>
                  {user?.results.map((userItem) => (
                    <option key={userItem.id} value={userItem.id}>{userItem.first_name} {userItem.last_name}</option>
                  ))}
                </Form.Select>
              </>
            ) : null}
            <Form.Label className={styles.labelForm}>Cliente :</Form.Label>
            <FormSelect className={`form-control ${styles.selectForm}`} value={clientFilter} onChange={e => setClientFilter(e.target.value)}>
              <option value="">Todos</option>
              {client.map(item =>
                <option key={item.id} value={item.id}>
                  {item.name} {item.name}
                </option>
              )}
            </FormSelect>
            <Form.Label className={styles.labelForm}>OPT :</Form.Label>
            <FormSelect className={`form-control ${styles.selectForm}`} value={opportunityFilter} onChange={e => setOpportunityFilter(e.target.value)}>
              <option value="">Todos</option>
              {opportunityValue.map(item =>
                <option key={item.id} value={item.opportunity_id}>
                  {item.opportunity_id} - {item.name}
                </option>
              )}
            </FormSelect>
            <Button className={`btn btn-light ${styles.searchButton}`} onClick={handleSearchPendingRac}>Buscar</Button>
          </Col>
        </Row>
      </Form>
      <Table striped bordered hover className={`table-responsive, ${styles.tableContainer}`} >
        <thead className={styles.tableHeadContainer}>
          <tr>
            <th className={styles.tableHeadElement}>RAC
              <OverlayTriggerComponent descriptionElement={'Número da RAC'}/>
            </th>
            <th className={styles.tableHeadElement}>Consultor
              <OverlayTriggerComponent descriptionElement={'Nome do consultor que está atendendo esta RAC'}/>
            </th>
            <th className={styles.tableHeadElement}>Cliente
              <OverlayTriggerComponent descriptionElement={'Número da Oportunidade e nome do Cliente'}/>
            </th>
            <th className={styles.tableHeadElement}>Criado em
              <OverlayTriggerComponent descriptionElement={'Data de criação do registro'}/>
            </th>
            <th className={styles.tableHeadElement}>Ações
              <OverlayTriggerComponent descriptionElement={'Ações de criação, atualização e deleção'}/>
            </th>
          </tr>
        </thead>
        <tbody className={styles.tableBodyContainer}>
          {itemsToShow && itemsToShow.length > 0 ? (
            itemsToShow.map((item) => (
              <tr key={item.id} className={styles.tableRowContainer}>
                <td className={styles.tableDataElement}>{item.id}</td>
                <td className={styles.tableDataElement}>{item.user_first_name} {item.user_last_name}</td>
                <td className={styles.tableDataElement}>{item.client_name}</td>
                <td className={styles.tableDataElement}>{formatDateForInput(item.create_at)}</td>
                <td className={styles.tableDataElement}>
                  <Link to={`/rac/saverac/${item.id}`}>
                    <PencilSquare className={styles.iconPencil} aria-label='Botão para atualizar um elemento da lista' title='Atualizar' />
                  </Link>
                  {rac.user_is_superuser ? (
                    <Trash3 className={styles.iconTrash} aria-label="Botão para excluir um elemento da lista" title='Excluir' onClick={() => handleClickDelete(item)} />
                  ) : null}
                </td>
              </tr>
            ))) : rac?.results ? (
              <EmptyMessage text_description={'Nenhuma'} text_data={'Rac'} registed={'cadastrada ainda.'} />
            ) : (
            <ListLoading />
          )}
        </tbody>
      </Table>

      <Pagination className='d-flex justify-content-center align-items-center' size="sm" >
        <Pagination.First onClick={() => handlePageChange(1)} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
        {Array.from({ length: Math.ceil(indice_number.length / itemsPerPage) }, (_, index) => (
          <Pagination.Item key={index} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)} linkStyle={index + 1 === currentPage ? { color: '#FFF', backgroundColor: '#00244D', borderColor: '#042d5c' } : { color: '#042d5c' }} >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === Math.ceil(indice_number.length / itemsPerPage)} />
        <Pagination.Last onClick={() => handlePageChange(Math.ceil(indice_number.length / itemsPerPage))} />
      </Pagination>
      <br />

      <ModalGeneralDelete show={show} handleClose={handleClose} removeElement={removeElementRac} element_name={'a RAC'} deleteElement={deleteId} describe_delete={'de número'} />

      <InfoMessageModal showModal={showModalStatus} onClose={() => setShowModalStatus(false)} modalTitle={modalTitle} modalMessage={modalMessage} />

    </Container>
  )
}

export default RacPending;
